import { CSSProperties } from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { HighlightCardVariant } from '@DS/components/foundations/shapes/card/types'
import { HALF_RADIUS } from '@DS/styles/constants/radius'
import { SPACING_0, SPACING_1, SPACING_4, SPACING_5 } from '@DS/styles/constants/spacing'
import { TagVariant } from '@Types/tag'

const getPadding = (isLarge: boolean, isFullWidth: boolean) => {
  const verticalPadding = rem(isLarge ? 5.5 : 5)

  return `${isFullWidth ? `calc(${verticalPadding} - ${rem(1)})` : verticalPadding} ${SPACING_1}`
}

export const tag = (isLarge = false, isFullWidth = false): SerializedStyles =>
  css({
    alignItems: 'center',
    display: 'inline-flex',
    minHeight: isLarge ? rem(32) : rem(26),
    ...(isFullWidth
      ? {
          width: '100%',
        }
      : {
          lineHeight: isLarge ? rem(26) : rem(20),
        }),
  })

export const contentWrapper = (isLarge = false, isFullWidth = false): SerializedStyles =>
  css(
    isFullWidth
      ? {
          width: '100%',
        }
      : {
          padding: isLarge ? `${rem(4)} 0 ${rem(3)}` : `${rem(3)} 0`,
        }
  )

export const content = (theme: Theme, isLarge = false, hasIcon = false, isFullWidth = false): SerializedStyles => {
  const iconPadding = isLarge ? SPACING_5 : SPACING_4

  return css({
    borderRadius: HALF_RADIUS,
    padding: getPadding(isLarge, isFullWidth),
    ...(!isFullWidth && {
      '&::before': {
        backgroundColor: 'inherit',
        bottom: SPACING_1,
        content: '""',
        display: 'block',
        left: 0,
        position: 'absolute',
        top: SPACING_1,
        width: SPACING_0,
      },
      boxDecorationBreak: 'clone', // utile si affichage du tag sur plusieurs lignes
      display: 'inline',
      position: 'relative',
    }),
    ...(hasIcon && {
      marginLeft: `-${iconPadding}`,
      paddingLeft: iconPadding,
    }),
  })
}

export const iconWrapper = css({
  flexShrink: 0,
  zIndex: 1,
})

export const icon = (isLarge = false): SerializedStyles =>
  css({
    ...size(rem(isLarge ? 24 : 16)),
    display: 'block',
    marginLeft: SPACING_1,
    marginRight: SPACING_1,
  })

export const getBackgrounds = (theme: Theme, variant: TagVariant): CSSProperties['color'] =>
  ({
    canceled: theme.palette.text.secondary,
    neutral: theme.customPalette.onElements.elevated,
    proactivity: theme.customPalette.alternative.service,
    promo: theme.customPalette.alternative.communication,
    success: theme.customPalette.alternative.success,
    tips: theme.customPalette.alternative.info,
    travel: theme.customPalette.alternative.extraIcon2,
    warning: theme.customPalette.alternative.warning,
  }[variant])

export const getHighlightBackgrounds = (theme: Theme, variant: HighlightCardVariant): CSSProperties['color'] =>
  ({
    proactivity: theme.customPalette.alternative.onService,
    promo: theme.customPalette.alternative.onCommunication,
    tips: theme.customPalette.alternative.onInfo,
    travel: theme.customPalette.alternative.onExtraIcon2,
  }[variant])

export const backgroundColors = (theme: Theme, variant: TagVariant, isOnButton: boolean): SerializedStyles =>
  css({
    backgroundColor: isOnButton ? 'transparent' : getBackgrounds(theme, variant),
  })

export const highlightBackgroundColors = (
  theme: Theme,
  variant: HighlightCardVariant,
  isOnButton: boolean
): SerializedStyles =>
  css({
    backgroundColor: isOnButton ? 'transparent' : getHighlightBackgrounds(theme, variant),
  })

export const colorTags = (theme: Theme, variant: TagVariant): CSSProperties['color'] => {
  const { onAlternativeColor3, onAlternativeColor1 } = theme.customPalette.alternative

  return {
    canceled: onAlternativeColor3,
    neutral: onAlternativeColor1,
    proactivity: onAlternativeColor3,
    promo: onAlternativeColor1,
    success: onAlternativeColor3,
    tips: onAlternativeColor3,
    travel: onAlternativeColor1,
    warning: onAlternativeColor3,
  }[variant]
}

export const colors = (theme: Theme, variant: TagVariant): SerializedStyles =>
  css({
    color: colorTags(theme, variant),
    fill: 'currentcolor',
  })
