import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { ReactComponent as SearchIcon } from '@Svg/navigationAndAction/search.svg'

import * as styles from './styles'

type AutocompleteNoResultsProps = {
  inputValue: string
}

const AutocompleteNoResults: FC<AutocompleteNoResultsProps> = ({ inputValue }) => {
  const theme = useTheme()

  return (
    <div css={styles.noResultsContainer} data-cs-override-id="search_suggestions-advanced">
      <SearchIcon css={styles.noResultsIcon} />
      <div css={styles.noResultsTextBox}>
        <Typography css={styles.noResultsTitle(theme)} variant="caption">
          <FormattedMessage id="autocomplete_NR_title" />
        </Typography>
        <Typography variant="body1">{`"${inputValue}"`}</Typography>
      </div>
    </div>
  )
}

export default AutocompleteNoResults
