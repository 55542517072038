import { Configuration } from 'invictus-sdk-typescript'
import { BuildManifest } from 'next/dist/server/get-page-files'

import { PublicRuntimeConfiguration } from '@Utils/config'

declare const PUBLIC_RUNTIME_CONFIG: PublicRuntimeConfiguration

declare const NEXT_BUILD_MANIFEST: BuildManifest

const getWidgetFrontendApiConfiguration = (): Configuration =>
  new Configuration({
    basePath: PUBLIC_RUNTIME_CONFIG.frontendApiURL,
  })

const widgetPublicRuntimeConfig: PublicRuntimeConfiguration = { ...PUBLIC_RUNTIME_CONFIG }

const widgetNextBuildManifest: BuildManifest = { ...NEXT_BUILD_MANIFEST }

export { getWidgetFrontendApiConfiguration, widgetPublicRuntimeConfig, widgetNextBuildManifest }
