import { FC, ReactElement, ReactNode } from 'react'

type ConditionalWrapperProps = {
  condition: boolean
  renderWrapper: (children?: ReactNode) => ReactElement
  renderDefaultWrapper?: (children?: ReactNode) => ReactElement
  children?: ReactNode
}

const ConditionalWrapper: FC<ConditionalWrapperProps> = ({
  condition,
  renderWrapper,
  renderDefaultWrapper,
  children,
}) => {
  if (condition) {
    return renderWrapper(children)
  }

  if (renderDefaultWrapper) {
    return renderDefaultWrapper(children)
  }

  return <>{children}</>
}

export default ConditionalWrapper
