import { type ControllerApiConstructor } from './abstract'
import { AbstractSelfInitSdk } from './abstractSelfInit'
import { type SearchType } from './autocomplete'
import { Catch } from './catchError'
import {
  type RecentSearchRequest,
  type RedirectionSuccess,
  SearchSuggestionsControllerApi,
  type SearchSuggestionsResult,
} from './generated'

export class SearchSuggestionsSdk extends AbstractSelfInitSdk<SearchSuggestionsControllerApi> {
  protected getControllerApiConstructor(): ControllerApiConstructor<SearchSuggestionsControllerApi> {
    return SearchSuggestionsControllerApi
  }

  @Catch<SearchSuggestionsResult>()
  async findSearchSuggestions(types?: SearchType[], autocompleteVisitCount?: number): Promise<SearchSuggestionsResult> {
    const response = await this.api.findSearchSuggestionsForType(
      this.userSdk.getBffHeader(),
      types,
      autocompleteVisitCount,
      this.userSdk.createAxiosOptions()
    )

    return response.data
  }

  @Catch<RedirectionSuccess>()
  async redirect(request: RecentSearchRequest): Promise<RedirectionSuccess> {
    const response = await this.api.redirect(this.userSdk.getBffHeader(), request, this.userSdk.createAxiosOptions())

    return response.data
  }
}
