import { css } from '@emotion/react'
import { rem, size } from 'polished'

import { SPACING_1 } from '@DS/styles/constants/spacing'

export const recentSearchResultContainer = css({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
})

export const recentSearchResultDestinationContainer = css({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'nowrap',
  minWidth: 0,
})

export const recentSearchLabel = css({
  overflow: 'hidden',
  paddingRight: SPACING_1,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

export const recentSearchInwardIcon = css({
  flexShrink: 0,
  ...size(rem(16)),
  marginRight: SPACING_1,
})
