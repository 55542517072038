import { type ControllerApiConstructor } from './abstract'
import { AbstractSelfInitSdk } from './abstractSelfInit'
import { Catch } from './catchError'
import {
  AutocompleteControllerApi,
  type AutocompleteLocation,
  type AutocompleteRequest,
  type AutocompleteResult,
  type AutocompleteResultOld,
  type HighlightedPlace,
  type Place,
  type TransporterCode,
} from './generated'

export type AutocompleteVisitCount = 0 | 1 | 2

export type SearchType = 'origin' | 'destination' | 'via' | 'itinerary'

export const isAutocompleteVisitCount = (value: unknown): value is AutocompleteVisitCount =>
  typeof value === 'number' && [0, 1, 2].includes(value)

export type AutocompleteSearchOptions = Omit<AutocompleteRequest, 'searchTerm'>

export class AutocompleteSdk extends AbstractSelfInitSdk<AutocompleteControllerApi> {
  protected getControllerApiConstructor(): ControllerApiConstructor<AutocompleteControllerApi> {
    return AutocompleteControllerApi
  }

  @Catch<AutocompleteResultOld>()
  async autocompleteOld(
    searchTerm: string,
    location?: AutocompleteLocation,
    keepStationsOnly?: boolean,
    isInFranceOnly?: boolean
  ): Promise<AutocompleteResultOld> {
    const request: AutocompleteRequest = {
      searchTerm,
      location,
      keepStationsOnly,
      isInFranceOnly,
    }
    const response = await this.api.findPlaces(this.userSdk.getBffHeader(), request, this.userSdk.createAxiosOptions())

    return response.data
  }

  @Catch<AutocompleteResult>()
  async autocomplete(searchTerm: string, searchOptions: AutocompleteSearchOptions = {}): Promise<AutocompleteResult> {
    return (
      await this.api.findSearchPlaces(
        this.userSdk.getBffHeader(),
        {
          searchTerm,
          ...searchOptions,
        },
        this.userSdk.createAxiosOptions()
      )
    ).data
  }
}

export const toPlace: (place: { id: string; label: string; codes?: TransporterCode[] }) => Place = ({
  id,
  label,
  codes = [],
}) => ({
  label,
  id,
  codes,
  geolocation: false,
})

export const toHighlightedPlace = (
  place: Place,
  iconId = {
    iconGroup: 'Mobile',
    iconName: 'station',
  }
): HighlightedPlace => ({
  label: place.label,
  id: place.id,
  highlight: [],
  lines: [],
  linesIcons: [],
  codes: [],
  type: {
    label: '',
    iconId,
  },
})
