/* tslint:disable */
/* eslint-disable */
/**
 * Invictus
 * Invictus Backend API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { OutputErrorMessage } from '../model';
// @ts-ignore
import { RecentSearchRequest } from '../model';
// @ts-ignore
import { RedirectionSuccess } from '../model';
// @ts-ignore
import { SearchSuggestionsResult } from '../model';
/**
 * SearchSuggestionsControllerApi - axios parameter creator
 * @export
 */
export const SearchSuggestionsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search suggestions for the given autocomplete type: origin, destination, via or itinerary
         * @param {string} xBffKey bff key header
         * @param {Array<string>} [searchType] 
         * @param {number} [autocompleteVisitCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSearchSuggestionsForType: async (xBffKey: string, searchType?: Array<string>, autocompleteVisitCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('findSearchSuggestionsForType', 'xBffKey', xBffKey)
            const localVarPath = `/api/v1/search-suggestions-for-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (searchType) {
                localVarQueryParameter['searchType'] = searchType;
            }

            if (autocompleteVisitCount !== undefined) {
                localVarQueryParameter['autocompleteVisitCount'] = autocompleteVisitCount;
            }

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get generic redirection data from query params
         * @param {string} xBffKey bff key header
         * @param {RecentSearchRequest} recentSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirect: async (xBffKey: string, recentSearchRequest: RecentSearchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('redirect', 'xBffKey', xBffKey)
            // verify required parameter 'recentSearchRequest' is not null or undefined
            assertParamExists('redirect', 'recentSearchRequest', recentSearchRequest)
            const localVarPath = `/api/v1/search-suggestions/redirect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recentSearchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchSuggestionsControllerApi - functional programming interface
 * @export
 */
export const SearchSuggestionsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchSuggestionsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search suggestions for the given autocomplete type: origin, destination, via or itinerary
         * @param {string} xBffKey bff key header
         * @param {Array<string>} [searchType] 
         * @param {number} [autocompleteVisitCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSearchSuggestionsForType(xBffKey: string, searchType?: Array<string>, autocompleteVisitCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchSuggestionsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSearchSuggestionsForType(xBffKey, searchType, autocompleteVisitCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get generic redirection data from query params
         * @param {string} xBffKey bff key header
         * @param {RecentSearchRequest} recentSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redirect(xBffKey: string, recentSearchRequest: RecentSearchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedirectionSuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redirect(xBffKey, recentSearchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchSuggestionsControllerApi - factory interface
 * @export
 */
export const SearchSuggestionsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchSuggestionsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Search suggestions for the given autocomplete type: origin, destination, via or itinerary
         * @param {string} xBffKey bff key header
         * @param {Array<string>} [searchType] 
         * @param {number} [autocompleteVisitCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSearchSuggestionsForType(xBffKey: string, searchType?: Array<string>, autocompleteVisitCount?: number, options?: any): AxiosPromise<SearchSuggestionsResult> {
            return localVarFp.findSearchSuggestionsForType(xBffKey, searchType, autocompleteVisitCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get generic redirection data from query params
         * @param {string} xBffKey bff key header
         * @param {RecentSearchRequest} recentSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirect(xBffKey: string, recentSearchRequest: RecentSearchRequest, options?: any): AxiosPromise<RedirectionSuccess> {
            return localVarFp.redirect(xBffKey, recentSearchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchSuggestionsControllerApi - object-oriented interface
 * @export
 * @class SearchSuggestionsControllerApi
 * @extends {BaseAPI}
 */
export class SearchSuggestionsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Search suggestions for the given autocomplete type: origin, destination, via or itinerary
     * @param {string} xBffKey bff key header
     * @param {Array<string>} [searchType] 
     * @param {number} [autocompleteVisitCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchSuggestionsControllerApi
     */
    public findSearchSuggestionsForType(xBffKey: string, searchType?: Array<string>, autocompleteVisitCount?: number, options?: AxiosRequestConfig) {
        return SearchSuggestionsControllerApiFp(this.configuration).findSearchSuggestionsForType(xBffKey, searchType, autocompleteVisitCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get generic redirection data from query params
     * @param {string} xBffKey bff key header
     * @param {RecentSearchRequest} recentSearchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchSuggestionsControllerApi
     */
    public redirect(xBffKey: string, recentSearchRequest: RecentSearchRequest, options?: AxiosRequestConfig) {
        return SearchSuggestionsControllerApiFp(this.configuration).redirect(xBffKey, recentSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
