import { SearchSuggestionsSdk } from 'invictus-sdk-typescript'

import { useSdkState } from '@Contexts/sdk/SdkContext'

export const useSearchSuggestionsSdk = (): SearchSuggestionsSdk => {
  const { searchSuggestionsSdk } = useSdkState()

  if (searchSuggestionsSdk === undefined) {
    throw new Error('useSearchSuggestionsSdk must be used within a SdkProvider with a searchSuggestionsSdk')
  }

  return searchSuggestionsSdk
}
