import { forwardRef } from 'react'
import { useIntl } from 'react-intl'
import { useTheme } from '@mui/material/styles'
import { LargeUp } from 'invictus-web-nextjs/src/components/utils/media/Responsive'

import ConditionalWrapper from '@DS/components/utils/wrapper/ConditionalWrapper'
import { ReactComponent as ArrowLeft } from '@Svg/navigationAndAction/arrow_left.svg'
import { ReactComponent as ArrowRight } from '@Svg/navigationAndAction/arrow_right.svg'

import { ScrollDirection } from '../Carousel.types'

import * as styles from './styles'

export type CarouselNavigationButtonsProps = {
  isDisplayedOnMediumDown?: boolean
  leftSemanticLabel?: string
  rightSemanticLabel?: string
  isScrolled: boolean
  isScrolledToEnd: boolean
  onClick: (direction: ScrollDirection) => void
}

const CarouselNavigationButtons = forwardRef<HTMLButtonElement, CarouselNavigationButtonsProps>(
  ({ isDisplayedOnMediumDown, leftSemanticLabel, rightSemanticLabel, isScrolled, isScrolledToEnd, onClick }, ref) => {
    const { formatMessage } = useIntl()
    const theme = useTheme()

    return (
      <ConditionalWrapper
        condition={!isDisplayedOnMediumDown}
        renderWrapper={(children) => <LargeUp>{children}</LargeUp>}
      >
        <div data-test="navigation-group">
          {isScrolled && (
            <button
              ref={isScrolledToEnd ? ref : null}
              type="button"
              aria-label={leftSemanticLabel || formatMessage({ id: 'previous' })}
              onClick={() => onClick('right-to-left')}
              css={styles.leftNavigationButton}
            >
              <ArrowLeft css={styles.arrowIcon(theme)} />
            </button>
          )}
          {!isScrolledToEnd && (
            <button
              ref={!isScrolled ? ref : null}
              type="button"
              aria-label={rightSemanticLabel || formatMessage({ id: 'next' })}
              onClick={() => onClick('left-to-right')}
              css={styles.rightNavigationButton}
            >
              <ArrowRight css={styles.arrowIcon(theme)} />
            </button>
          )}
        </div>
      </ConditionalWrapper>
    )
  }
)

export default CarouselNavigationButtons
