/* tslint:disable */
/* eslint-disable */
/**
 * Invictus
 * Invictus Backend API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddFavoriteItineraryRequest } from '../model';
// @ts-ignore
import { AddFavoriteItinerarySuccess } from '../model';
// @ts-ignore
import { FavoriteItinerariesSuccess } from '../model';
// @ts-ignore
import { GetItineraryOutput } from '../model';
// @ts-ignore
import { ItineraryOutput } from '../model';
// @ts-ignore
import { ItineraryRequest } from '../model';
// @ts-ignore
import { MoreItinerariesRequest } from '../model';
// @ts-ignore
import { OutputErrorMessage } from '../model';
// @ts-ignore
import { SeatMap } from '../model';
// @ts-ignore
import { UpdateFavoriteItineraryAlertRequest } from '../model';
// @ts-ignore
import { UpdateFavoriteItineraryAlertSuccess } from '../model';
/**
 * ItineraryControllerApi - axios parameter creator
 * @export
 */
export const ItineraryControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add favorite itinerary
         * @param {string} xBffKey bff key header
         * @param {AddFavoriteItineraryRequest} addFavoriteItineraryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFavoriteItinerary: async (xBffKey: string, addFavoriteItineraryRequest: AddFavoriteItineraryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('addFavoriteItinerary', 'xBffKey', xBffKey)
            // verify required parameter 'addFavoriteItineraryRequest' is not null or undefined
            assertParamExists('addFavoriteItinerary', 'addFavoriteItineraryRequest', addFavoriteItineraryRequest)
            const localVarPath = `/api/v1/itineraries/favorite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addFavoriteItineraryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete favorite itinerary alert
         * @param {string} itineraryId 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFavoriteItinerary: async (itineraryId: string, xBffKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itineraryId' is not null or undefined
            assertParamExists('deleteFavoriteItinerary', 'itineraryId', itineraryId)
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('deleteFavoriteItinerary', 'xBffKey', xBffKey)
            const localVarPath = `/api/v1/itineraries/favorite/{itineraryId}`
                .replace(`{${"itineraryId"}}`, encodeURIComponent(String(itineraryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get favorite itineraries
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteItineraries: async (xBffKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('favoriteItineraries', 'xBffKey', xBffKey)
            const localVarPath = `/api/v1/itineraries/favorite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find last itinerary
         * @param {string} itineraryId 
         * @param {string} xBffKey bff key header
         * @param {boolean} [outward] 
         * @param {boolean} [plan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItinerary: async (itineraryId: string, xBffKey: string, outward?: boolean, plan?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itineraryId' is not null or undefined
            assertParamExists('getItinerary', 'itineraryId', itineraryId)
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('getItinerary', 'xBffKey', xBffKey)
            const localVarPath = `/api/v1/itineraries/{itineraryId}`
                .replace(`{${"itineraryId"}}`, encodeURIComponent(String(itineraryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (outward !== undefined) {
                localVarQueryParameter['outward'] = outward;
            }

            if (plan !== undefined) {
                localVarQueryParameter['plan'] = plan;
            }

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get seat map
         * @param {string} itineraryId 
         * @param {string} offerId 
         * @param {string} segmentId 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeatMap: async (itineraryId: string, offerId: string, segmentId: string, xBffKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itineraryId' is not null or undefined
            assertParamExists('getSeatMap', 'itineraryId', itineraryId)
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('getSeatMap', 'offerId', offerId)
            // verify required parameter 'segmentId' is not null or undefined
            assertParamExists('getSeatMap', 'segmentId', segmentId)
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('getSeatMap', 'xBffKey', xBffKey)
            const localVarPath = `/api/v1/itineraries/{itineraryId}/offers/{offerId}/segments/{segmentId}/seatmap`
                .replace(`{${"itineraryId"}}`, encodeURIComponent(String(itineraryId)))
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)))
                .replace(`{${"segmentId"}}`, encodeURIComponent(String(segmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find itineraries
         * @param {string} xBffKey bff key header
         * @param {ItineraryRequest} itineraryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itineraries: async (xBffKey: string, itineraryRequest: ItineraryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('itineraries', 'xBffKey', xBffKey)
            // verify required parameter 'itineraryRequest' is not null or undefined
            assertParamExists('itineraries', 'itineraryRequest', itineraryRequest)
            const localVarPath = `/api/v1/itineraries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itineraryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find additional itineraries
         * @param {string} xBffKey bff key header
         * @param {MoreItinerariesRequest} moreItinerariesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moreItineraries: async (xBffKey: string, moreItinerariesRequest: MoreItinerariesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('moreItineraries', 'xBffKey', xBffKey)
            // verify required parameter 'moreItinerariesRequest' is not null or undefined
            assertParamExists('moreItineraries', 'moreItinerariesRequest', moreItinerariesRequest)
            const localVarPath = `/api/v1/itineraries/more`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moreItinerariesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Itinerary Alert
         * @param {string} itineraryId 
         * @param {string} xBffKey bff key header
         * @param {UpdateFavoriteItineraryAlertRequest} updateFavoriteItineraryAlertRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItineraryAlert: async (itineraryId: string, xBffKey: string, updateFavoriteItineraryAlertRequest: UpdateFavoriteItineraryAlertRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itineraryId' is not null or undefined
            assertParamExists('updateItineraryAlert', 'itineraryId', itineraryId)
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('updateItineraryAlert', 'xBffKey', xBffKey)
            // verify required parameter 'updateFavoriteItineraryAlertRequest' is not null or undefined
            assertParamExists('updateItineraryAlert', 'updateFavoriteItineraryAlertRequest', updateFavoriteItineraryAlertRequest)
            const localVarPath = `/api/v1/itineraries/favorite/alert/update/{itineraryId}`
                .replace(`{${"itineraryId"}}`, encodeURIComponent(String(itineraryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFavoriteItineraryAlertRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItineraryControllerApi - functional programming interface
 * @export
 */
export const ItineraryControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItineraryControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add favorite itinerary
         * @param {string} xBffKey bff key header
         * @param {AddFavoriteItineraryRequest} addFavoriteItineraryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFavoriteItinerary(xBffKey: string, addFavoriteItineraryRequest: AddFavoriteItineraryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddFavoriteItinerarySuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFavoriteItinerary(xBffKey, addFavoriteItineraryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete favorite itinerary alert
         * @param {string} itineraryId 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFavoriteItinerary(itineraryId: string, xBffKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFavoriteItinerary(itineraryId, xBffKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get favorite itineraries
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async favoriteItineraries(xBffKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FavoriteItinerariesSuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.favoriteItineraries(xBffKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find last itinerary
         * @param {string} itineraryId 
         * @param {string} xBffKey bff key header
         * @param {boolean} [outward] 
         * @param {boolean} [plan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItinerary(itineraryId: string, xBffKey: string, outward?: boolean, plan?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetItineraryOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItinerary(itineraryId, xBffKey, outward, plan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get seat map
         * @param {string} itineraryId 
         * @param {string} offerId 
         * @param {string} segmentId 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeatMap(itineraryId: string, offerId: string, segmentId: string, xBffKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeatMap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSeatMap(itineraryId, offerId, segmentId, xBffKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find itineraries
         * @param {string} xBffKey bff key header
         * @param {ItineraryRequest} itineraryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itineraries(xBffKey: string, itineraryRequest: ItineraryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItineraryOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itineraries(xBffKey, itineraryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find additional itineraries
         * @param {string} xBffKey bff key header
         * @param {MoreItinerariesRequest} moreItinerariesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moreItineraries(xBffKey: string, moreItinerariesRequest: MoreItinerariesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItineraryOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moreItineraries(xBffKey, moreItinerariesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Itinerary Alert
         * @param {string} itineraryId 
         * @param {string} xBffKey bff key header
         * @param {UpdateFavoriteItineraryAlertRequest} updateFavoriteItineraryAlertRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateItineraryAlert(itineraryId: string, xBffKey: string, updateFavoriteItineraryAlertRequest: UpdateFavoriteItineraryAlertRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateFavoriteItineraryAlertSuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateItineraryAlert(itineraryId, xBffKey, updateFavoriteItineraryAlertRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItineraryControllerApi - factory interface
 * @export
 */
export const ItineraryControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItineraryControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Add favorite itinerary
         * @param {string} xBffKey bff key header
         * @param {AddFavoriteItineraryRequest} addFavoriteItineraryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFavoriteItinerary(xBffKey: string, addFavoriteItineraryRequest: AddFavoriteItineraryRequest, options?: any): AxiosPromise<AddFavoriteItinerarySuccess> {
            return localVarFp.addFavoriteItinerary(xBffKey, addFavoriteItineraryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete favorite itinerary alert
         * @param {string} itineraryId 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFavoriteItinerary(itineraryId: string, xBffKey: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteFavoriteItinerary(itineraryId, xBffKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get favorite itineraries
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteItineraries(xBffKey: string, options?: any): AxiosPromise<FavoriteItinerariesSuccess> {
            return localVarFp.favoriteItineraries(xBffKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find last itinerary
         * @param {string} itineraryId 
         * @param {string} xBffKey bff key header
         * @param {boolean} [outward] 
         * @param {boolean} [plan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItinerary(itineraryId: string, xBffKey: string, outward?: boolean, plan?: boolean, options?: any): AxiosPromise<GetItineraryOutput> {
            return localVarFp.getItinerary(itineraryId, xBffKey, outward, plan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get seat map
         * @param {string} itineraryId 
         * @param {string} offerId 
         * @param {string} segmentId 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeatMap(itineraryId: string, offerId: string, segmentId: string, xBffKey: string, options?: any): AxiosPromise<SeatMap> {
            return localVarFp.getSeatMap(itineraryId, offerId, segmentId, xBffKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find itineraries
         * @param {string} xBffKey bff key header
         * @param {ItineraryRequest} itineraryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itineraries(xBffKey: string, itineraryRequest: ItineraryRequest, options?: any): AxiosPromise<ItineraryOutput> {
            return localVarFp.itineraries(xBffKey, itineraryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find additional itineraries
         * @param {string} xBffKey bff key header
         * @param {MoreItinerariesRequest} moreItinerariesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moreItineraries(xBffKey: string, moreItinerariesRequest: MoreItinerariesRequest, options?: any): AxiosPromise<ItineraryOutput> {
            return localVarFp.moreItineraries(xBffKey, moreItinerariesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Itinerary Alert
         * @param {string} itineraryId 
         * @param {string} xBffKey bff key header
         * @param {UpdateFavoriteItineraryAlertRequest} updateFavoriteItineraryAlertRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItineraryAlert(itineraryId: string, xBffKey: string, updateFavoriteItineraryAlertRequest: UpdateFavoriteItineraryAlertRequest, options?: any): AxiosPromise<UpdateFavoriteItineraryAlertSuccess> {
            return localVarFp.updateItineraryAlert(itineraryId, xBffKey, updateFavoriteItineraryAlertRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItineraryControllerApi - object-oriented interface
 * @export
 * @class ItineraryControllerApi
 * @extends {BaseAPI}
 */
export class ItineraryControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add favorite itinerary
     * @param {string} xBffKey bff key header
     * @param {AddFavoriteItineraryRequest} addFavoriteItineraryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryControllerApi
     */
    public addFavoriteItinerary(xBffKey: string, addFavoriteItineraryRequest: AddFavoriteItineraryRequest, options?: AxiosRequestConfig) {
        return ItineraryControllerApiFp(this.configuration).addFavoriteItinerary(xBffKey, addFavoriteItineraryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete favorite itinerary alert
     * @param {string} itineraryId 
     * @param {string} xBffKey bff key header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryControllerApi
     */
    public deleteFavoriteItinerary(itineraryId: string, xBffKey: string, options?: AxiosRequestConfig) {
        return ItineraryControllerApiFp(this.configuration).deleteFavoriteItinerary(itineraryId, xBffKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get favorite itineraries
     * @param {string} xBffKey bff key header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryControllerApi
     */
    public favoriteItineraries(xBffKey: string, options?: AxiosRequestConfig) {
        return ItineraryControllerApiFp(this.configuration).favoriteItineraries(xBffKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find last itinerary
     * @param {string} itineraryId 
     * @param {string} xBffKey bff key header
     * @param {boolean} [outward] 
     * @param {boolean} [plan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryControllerApi
     */
    public getItinerary(itineraryId: string, xBffKey: string, outward?: boolean, plan?: boolean, options?: AxiosRequestConfig) {
        return ItineraryControllerApiFp(this.configuration).getItinerary(itineraryId, xBffKey, outward, plan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get seat map
     * @param {string} itineraryId 
     * @param {string} offerId 
     * @param {string} segmentId 
     * @param {string} xBffKey bff key header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryControllerApi
     */
    public getSeatMap(itineraryId: string, offerId: string, segmentId: string, xBffKey: string, options?: AxiosRequestConfig) {
        return ItineraryControllerApiFp(this.configuration).getSeatMap(itineraryId, offerId, segmentId, xBffKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find itineraries
     * @param {string} xBffKey bff key header
     * @param {ItineraryRequest} itineraryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryControllerApi
     */
    public itineraries(xBffKey: string, itineraryRequest: ItineraryRequest, options?: AxiosRequestConfig) {
        return ItineraryControllerApiFp(this.configuration).itineraries(xBffKey, itineraryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find additional itineraries
     * @param {string} xBffKey bff key header
     * @param {MoreItinerariesRequest} moreItinerariesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryControllerApi
     */
    public moreItineraries(xBffKey: string, moreItinerariesRequest: MoreItinerariesRequest, options?: AxiosRequestConfig) {
        return ItineraryControllerApiFp(this.configuration).moreItineraries(xBffKey, moreItinerariesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Itinerary Alert
     * @param {string} itineraryId 
     * @param {string} xBffKey bff key header
     * @param {UpdateFavoriteItineraryAlertRequest} updateFavoriteItineraryAlertRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryControllerApi
     */
    public updateItineraryAlert(itineraryId: string, xBffKey: string, updateFavoriteItineraryAlertRequest: UpdateFavoriteItineraryAlertRequest, options?: AxiosRequestConfig) {
        return ItineraryControllerApiFp(this.configuration).updateItineraryAlert(itineraryId, xBffKey, updateFavoriteItineraryAlertRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
