import { forwardRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import Button, { ButtonProps } from '@DS/components/forms/button/Button'
import { ReactComponent as CartIcon } from '@Svg/payment/cart.svg'

import * as styles from './styles'

type ButtonCartKind = 'compact' | 'normal'
export type ButtonCartProps = { itemCount: number; kind?: ButtonCartKind }

const ButtonCart = forwardRef<HTMLButtonElement & HTMLAnchorElement, ButtonCartProps & ButtonProps<'a'>>(
  ({ itemCount, kind = 'normal', ...rest }, ref) => {
    const { formatMessage } = useIntl()
    const theme = useTheme()

    return (
      <Button
        ref={ref}
        {...rest}
        component="a"
        css={styles.container(theme, kind === 'normal')}
        aria-label={formatMessage({ id: 'floatingCart_semanticsLabel' }, { count: itemCount })}
      >
        {kind === 'normal' && (
          <Typography component="span" variant="body1">
            <FormattedMessage id="cart" />
          </Typography>
        )}

        <Typography
          component="span"
          variant="body1"
          align="center"
          css={styles.cartItemCounter(kind === 'normal')}
          aria-hidden
        >
          <CartIcon css={styles.cartIcon} />
          {itemCount || ''}
        </Typography>
      </Button>
    )
  }
)

export default ButtonCart
