import { forwardRef, ForwardRefRenderFunction, memo } from 'react'
import { useTheme } from '@mui/material/styles'
import { RecentSearch } from 'invictus-sdk-typescript'

import { AutocompleteItemProps } from '@DS/components/navigation/search/autocomplete/Autocomplete.types'
import ItineraryResult from '@DS/components/navigation/search/autocompleteResult/recentSearchResult/_internal/ItineraryResult'
import SimpleResult from '@DS/components/navigation/search/autocompleteResult/recentSearchResult/_internal/SimpleResult'
import { ReactComponent as HistoryIcon } from '@Svg/divers/history.svg'

import * as mainStyles from '../styles'

export type RecentSearchResultProps = AutocompleteItemProps<RecentSearch>

const AutocompleteRecentSearchResult: ForwardRefRenderFunction<HTMLDivElement, RecentSearchResultProps> = (
  { model, isHighlighted },
  ref
) => {
  const theme = useTheme()
  const { placeType, label, itineraryInformation, description, semanticDescription } = model

  const isSimpleRecentSearch: boolean = !!placeType && !itineraryInformation

  return (
    <div ref={ref} css={[mainStyles.autocompleteResult(theme, isHighlighted)]} aria-label={semanticDescription}>
      <HistoryIcon css={[mainStyles.autocompleteResultPlaceIconContainer, mainStyles.placeIcon(theme)]} />
      <div css={mainStyles.autocompleteResultPlaceData}>
        {isSimpleRecentSearch ? (
          <SimpleResult placeType={placeType} label={label} />
        ) : (
          <ItineraryResult label={label} itineraryInformation={itineraryInformation} description={description} />
        )}
      </div>
    </div>
  )
}

export default memo(forwardRef(AutocompleteRecentSearchResult))
