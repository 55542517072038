import { ElementType, FC } from 'react'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { colors } from '@DS/components/components/tag/styles'
import Tag, { TagProps } from '@DS/components/components/tag/Tag'
import BasicCard, { BasicCardProps } from '@DS/components/foundations/shapes/card/basic/BasicCard'
import VisuallyHiddenLabel from '@DS/components/utils/aria/VisuallyHiddenLabel'
import ConditionalWrapper from '@DS/components/utils/wrapper/ConditionalWrapper'

import { HighlightCardVariant } from '../types'

import * as styles from './styles'

export type HighlightCardProps = Omit<BasicCardProps, 'variant'> &
  Pick<TagProps, 'icon'> & { variant: HighlightCardVariant } & {
    title: string
    subtitle: string
    semanticSubtitle?: string
    isOnSuggestion?: boolean
    isFullWidth?: boolean
    hasInnerCard?: boolean
    titleComponent?: ElementType
  }

const HighlightCard: FC<HighlightCardProps> = ({
  children,
  hasInnerCard = false,
  isFullWidth = false,
  subtitle,
  semanticSubtitle,
  title,
  titleComponent,
  variant,
  isOnSuggestion = false,
  icon,
  ...rest
}) => {
  const theme = useTheme()

  return (
    <BasicCard {...rest} css={[styles.backgroundColors(theme, variant), colors(theme, variant)]}>
      <div css={styles.header(theme, isOnSuggestion, isFullWidth)}>
        <Tag
          mode={{ isOnHighlightCard: true, variant }}
          icon={icon}
          withIcon
          css={styles.tag}
          component={titleComponent}
        >
          {title}
        </Tag>

        <Typography variant="body1" css={styles.subtitle(theme, isFullWidth)} aria-hidden={!!semanticSubtitle}>
          {subtitle}
        </Typography>
        {semanticSubtitle && <VisuallyHiddenLabel label={semanticSubtitle} />}
      </div>

      <ConditionalWrapper
        condition={hasInnerCard}
        renderWrapper={(_children) => <div css={styles.innerCard}>{_children}</div>}
      >
        {children}
      </ConditionalWrapper>
    </BasicCard>
  )
}

export default HighlightCard
