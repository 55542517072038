import { Theme } from '@mui/material/styles'

import { AuthorizedColor } from './types'

export const getColor = (theme: Theme, colorName: AuthorizedColor): string => {
  switch (colorName) {
    case 'onAlternativeColor1':
      return theme.customPalette.alternative.onAlternativeColor1 as string
    case 'onAlternativeColor2':
      return theme.customPalette.alternative.onAlternativeColor2 as string
    case 'communication':
      return theme.customPalette.alternative.communication as string
    case 'service':
      return theme.customPalette.alternative.service as string
    case 'ecology':
      return theme.customPalette.alternative.ecology as string
    case 'outline':
      return theme.customPalette.onElements.outline as string
    case 'primary':
      return theme.palette.primary.main
    case 'success':
      return theme.palette.success.main
    case 'warning':
      return theme.palette.warning.main
    case 'error':
      return theme.palette.error.main
    case 'info':
    default:
      return theme.customPalette.alternative.info as string
  }
}

export const DEFAULT_MOMENT_COLOR = 'info'
export const DEFAULT_SNAP_COLOR = 'onAlternativeColor1'
