import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem } from 'polished'

import { Z_INDEX_HIGH } from '@Styles/constants/layout'

const navigationButton = css({
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  position: 'absolute',
  top: '50%',
  zIndex: Z_INDEX_HIGH,
})

export const leftNavigationButton = css(navigationButton, { left: '0', transform: 'translate(0%, -50%)' })

export const rightNavigationButton = css(navigationButton, { right: '0', transform: 'translate(0%, -50%)' })

export const arrowIcon = (theme: Theme): SerializedStyles =>
  css({
    fill: theme.palette.primary.main,
    height: rem(32),
  })
