import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { spacing, SPACING_1, SPACING_2 } from '@DS/styles/constants/spacing'

export const container = (theme: Theme, isNormal = false): SerializedStyles =>
  css({
    '&:focus': {
      background: theme.customPalette.alternative.cartHover,
    },
    '&:hover': {
      background: theme.customPalette.alternative.cartHover,
    },
    alignItems: 'center',
    background: theme.customPalette.alternative.cart,
    display: 'flex',
    height: isNormal ? rem(48) : rem(40),
    justifyContent: isNormal ? 'space-around' : 'center',
    minHeight: 'unset',
    minWidth: isNormal ? rem(120) : rem(74),
    padding: isNormal ? `${spacing(1.5)} ${SPACING_2}` : `${SPACING_1} ${SPACING_2}`,
    width: 'max-content',
  })

export const cartItemCounter = (isNormal = false): SerializedStyles =>
  css({
    alignItems: isNormal ? 'center' : 'flex-end',
    alignSelf: 'self-end',
    display: 'flex',
    justifyContent: isNormal ? 'center' : 'space-between',
    width: isNormal ? 'auto' : '100%',
  })

export const cartIcon = css({
  fill: 'currentcolor',
  ...size(rem(24)),
})
