import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { colors, colorTags } from '@DS/components/components/tag/styles'
import VisuallyHiddenLabel from '@DS/components/utils/aria/VisuallyHiddenLabel'
import { ReactComponent as CloseAndroidDark } from '@DS/svg/native/close_android_dark.svg'
import { TagVariant } from '@Types/tag'

import * as styles from '../proactivity/suggestionCard/_internal/styles'

type CloseButtonProps = {
  variant: TagVariant
  onClose: VoidFunction
  isFullWidth?: boolean
}

const CloseButton: FC<CloseButtonProps> = ({ variant, onClose, isFullWidth = false, ...rest }) => {
  const theme = useTheme()
  const styleColors = colors(theme, variant)
  const focusColor = colorTags(theme, variant)

  return (
    <IconButton
      {...rest}
      onClick={onClose}
      size="large"
      css={[styles.close(theme, isFullWidth, focusColor), styleColors]}
    >
      <CloseAndroidDark css={styles.closeIcon} />
      <VisuallyHiddenLabel label={<FormattedMessage id="close" />} />
    </IconButton>
  )
}

export default CloseButton
