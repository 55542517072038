import { FC } from 'react'

import AutocompleteResult from '@DS/components/navigation/search/autocompleteResult/AutocompleteResult'
import { AutocompleteResultModel } from '@Utils/autocomplete'

import { AutocompleteBaseProps } from './Autocomplete.types'
import GenericAutocomplete from './GenericAutocomplete'

export type AutocompleteProps = AutocompleteBaseProps<AutocompleteResultModel>

const Autocomplete: FC<AutocompleteProps> = ({ autocompleteRef, children, ...props }) => (
  <GenericAutocomplete AutocompleteItemComponent={AutocompleteResult} {...props} ref={autocompleteRef}>
    {children}
  </GenericAutocomplete>
)

export default Autocomplete
