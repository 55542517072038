import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { SPACING_2, SPACING_3 } from '@DS/styles/constants/spacing'

export const tutoSearch = (theme: Theme): SerializedStyles =>
  css({
    margin: `0 ${SPACING_2}`,
    [theme.breakpoints.up('md')]: {
      margin: `0 ${SPACING_3}`,
    },
  })

export const noResultsTitle = (theme: Theme): SerializedStyles =>
  css({
    color: theme.palette.text.secondary,
  })

export const noResultsContainer = css({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  padding: `${SPACING_2} ${SPACING_3} 0 ${SPACING_3}`,
})

export const noResultsTextBox = css({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  paddingLeft: SPACING_2,
})

export const noResultsIcon = css(size(rem(24)))
