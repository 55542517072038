import { FC } from 'react'
import { useTheme } from '@mui/material/styles'

import CloseButton from '@DS/components/components/atoms/CloseButton'
import HighlightCard from '@DS/components/foundations/shapes/card/highlight/HighlightCard'

import * as styles from './styles'

type OlympicGamesTipsProps = {
  title: string
  subtitle: string
  onClose: VoidFunction
}

const OlympicGamesTips: FC<OlympicGamesTipsProps> = ({ title, subtitle, onClose, ...rest }) => {
  const theme = useTheme()

  return (
    <div {...rest} css={styles.wrapper(theme)}>
      <HighlightCard css={styles.olympicGames} title={title} subtitle={subtitle} variant="tips" isFullWidth>
        <CloseButton data-cs-override-id="search_push-JO_close" isFullWidth variant="tips" onClose={onClose} />
      </HighlightCard>
    </div>
  )
}

export default OlympicGamesTips
