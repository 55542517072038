import {
  Children,
  forwardRef,
  HTMLAttributes,
  RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTheme } from '@mui/material/styles'
import { debounce } from 'debounce'

import ConditionalWrapper from '@DS/components/utils/wrapper/ConditionalWrapper'
import { SPACING_BASE } from '@DS/styles/constants/spacing'

import CarouselNavigationButtons, { CarouselNavigationButtonsProps } from './_internal/CarouselNavigationButtons'
import {
  CAROUSEL_DEFAULT_ITEMS_GAP,
  CAROUSEL_DEFAULT_SPACING_AROUND,
  CAROUSEL_MULTILINE_MAX_ITEMS_PER_LINE,
  CAROUSEL_SCROLL_STATUS_DEBOUNCE_TIME,
} from './Carousel.constants'
import { ScrollDirection } from './Carousel.types'
import * as styles from './styles'

type ArrowButtonElevation = 'onBackground' | 'onSurface'

type CarouselVariantProps =
  | {
      isMultiline?: false
      itemsGap?: number
      spacingAround?: number
    }
  | {
      isMultiline?: true
      itemsGap?: never
      spacingAround?: never
    }

type CarouselMediumUpGridDisplayRules = {
  mdColumnsCount: number
  lgColumnsCount: number
}

type NavigationButtonsProps =
  | {
      hasNavigationButtons: true
      navigationButtonOptions?: { hasSmallNavigationButtonsIncrement?: boolean } & Pick<
        CarouselNavigationButtonsProps,
        'isDisplayedOnMediumDown' | 'leftSemanticLabel' | 'rightSemanticLabel'
      >
    }
  | {
      hasNavigationButtons?: false
      navigationButtonOptions?: never
    }

type ContainerComponent = 'ul' | 'div'

type CarouselProps = {
  containerComponent?: ContainerComponent
  areItemsCentered?: boolean
  elevation?: ArrowButtonElevation
  isScrollSnapDisabled?: boolean
  isMouseScrollDisabled?: boolean
  // Display grid instead of carousel on mediumUp
  mediumUpGridDisplayRules?: CarouselMediumUpGridDisplayRules
} & CarouselVariantProps &
  NavigationButtonsProps

type RefType = HTMLDivElement | HTMLUListElement

const isUl = (_ref: RefObject<RefType>, containerComponent: ContainerComponent): _ref is RefObject<HTMLUListElement> =>
  containerComponent === 'ul'
const isDiv = (_ref: RefObject<RefType>, containerComponent: ContainerComponent): _ref is RefObject<HTMLDivElement> =>
  containerComponent === 'div'

const Carousel = forwardRef<RefType, CarouselProps & HTMLAttributes<RefType>>(
  (
    {
      children,
      containerComponent = 'ul',
      itemsGap = CAROUSEL_DEFAULT_ITEMS_GAP,
      spacingAround = CAROUSEL_DEFAULT_SPACING_AROUND,
      areItemsCentered = false,
      isMultiline = false,
      elevation = 'onBackground',
      isScrollSnapDisabled = false,
      isMouseScrollDisabled = false,
      mediumUpGridDisplayRules,
      hasNavigationButtons = false,
      navigationButtonOptions,
      ...rest
    },
    ref
  ) => {
    const theme = useTheme()
    const [scrollPosition, setScrollPosition] = useState(0)
    const [isScrollable, setIsScrollable] = useState(false)

    const carouselWrapperRef = useRef<HTMLDivElement>(null)
    const carouselRef = useRef<RefType>(null)
    useImperativeHandle<RefType | null, RefType | null>(ref, () => carouselRef.current)
    const isScrolledByClick = useRef(false)
    const scrollButtonRef = useRef<HTMLButtonElement>(null)

    const isScrolled = scrollPosition > SPACING_BASE * spacingAround

    const maxScrollPosition =
      carouselRef.current &&
      carouselWrapperRef.current &&
      carouselRef.current.scrollWidth - carouselWrapperRef.current.offsetWidth

    const isScrolledToEnd = !isScrollable || !!(maxScrollPosition && scrollPosition >= maxScrollPosition)
    const isElevated = elevation === 'onSurface'

    const scrollTo = (direction: ScrollDirection) => {
      const scrollViewPort = carouselWrapperRef.current?.offsetWidth || 0
      const scrollIncrement = navigationButtonOptions?.hasSmallNavigationButtonsIncrement
        ? scrollViewPort / 2
        : scrollViewPort

      isScrolledByClick.current = true
      setScrollPosition((prev) => (direction === 'right-to-left' ? prev - scrollIncrement : prev + scrollIncrement))
    }

    const updateScrollPosition = () => {
      if (isScrolledByClick.current) {
        isScrolledByClick.current = false

        return
      }
      setScrollPosition(carouselRef.current?.scrollLeft || 0)
    }

    useEffect(() => {
      const carousel = carouselRef.current
      const handleScroll = debounce(updateScrollPosition, CAROUSEL_SCROLL_STATUS_DEBOUNCE_TIME)

      setIsScrollable(carousel?.scrollWidth !== carousel?.offsetWidth)

      carousel?.addEventListener('scroll', handleScroll)

      return () => {
        carousel?.removeEventListener('scroll', handleScroll)
      }
    }, [carouselRef.current?.scrollWidth])

    useEffect(updateScrollPosition, [children])

    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (isScrollable && carouselRef.current && carouselRef.current.scroll) {
        carouselRef.current.scroll({ left: scrollPosition, behavior: 'smooth' })
      }
    }, [scrollPosition, isScrollable])

    useEffect(() => {
      if (isScrolledByClick.current) {
        scrollButtonRef.current?.focus()
      }
    }, [isScrolledToEnd, isScrolled])

    if (isMultiline) {
      const childrenCount = Children.count(children)
      const isEligibleForTwoLines =
        childrenCount > CAROUSEL_MULTILINE_MAX_ITEMS_PER_LINE / 2 &&
        childrenCount < CAROUSEL_MULTILINE_MAX_ITEMS_PER_LINE * 2
      const hasToBreakLine = (index: number) => index === Math.round(childrenCount / 2)

      return (
        <div
          {...rest}
          ref={carouselWrapperRef}
          css={styles.multilineCarouselWrapper(theme, { spacingAround, isScrolled, isScrolledToEnd, isElevated })}
        >
          <ul
            ref={isUl(carouselRef, containerComponent) ? carouselRef : undefined}
            css={styles.multilineCarouselContainer(theme, { spacingAround, areItemsCentered })}
            role="list"
          >
            {Children.map(children, (child, index) => (
              <li css={isEligibleForTwoLines && hasToBreakLine(index) && styles.breakLineItem}>{child}</li>
            ))}
          </ul>
          {hasNavigationButtons && (
            <CarouselNavigationButtons
              isScrolled={isScrolled}
              isScrolledToEnd={isScrolledToEnd}
              leftSemanticLabel={navigationButtonOptions?.leftSemanticLabel}
              rightSemanticLabel={navigationButtonOptions?.rightSemanticLabel}
              onClick={scrollTo}
              isDisplayedOnMediumDown={navigationButtonOptions?.isDisplayedOnMediumDown}
            />
          )}
        </div>
      )
    }

    return (
      <div
        {...rest}
        ref={carouselWrapperRef}
        css={styles.wrapper(theme, {
          isElevated,
          isScrolled,
          isScrolledToEnd,
          mediumUpGridDisplayRules,
          spacingAround,
        })}
      >
        <ConditionalWrapper
          condition={containerComponent === 'ul'}
          renderWrapper={(_children) => (
            <ul
              ref={isUl(carouselRef, containerComponent) ? carouselRef : undefined}
              css={styles.carouselContainer(theme, {
                spacingAround,
                itemsGap,
                areItemsCentered,
                isScrollSnapDisabled,
                mediumUpGridDisplayRules,
                isMouseScrollDisabled,
              })}
              role="list"
            >
              {Children.map(_children, (child) => (
                <li css={styles.ulListItem}>{child}</li>
              ))}
            </ul>
          )}
          renderDefaultWrapper={(_children) => (
            <div
              ref={isDiv(carouselRef, containerComponent) ? carouselRef : undefined}
              css={styles.carouselContainer(theme, {
                spacingAround,
                itemsGap,
                areItemsCentered,
                isScrollSnapDisabled,
                mediumUpGridDisplayRules,
                isMouseScrollDisabled,
              })}
            >
              {_children}
            </div>
          )}
        >
          {children}
        </ConditionalWrapper>
        {hasNavigationButtons && (
          <CarouselNavigationButtons
            ref={scrollButtonRef}
            isScrolled={isScrolled}
            isScrolledToEnd={isScrolledToEnd}
            leftSemanticLabel={navigationButtonOptions?.leftSemanticLabel}
            rightSemanticLabel={navigationButtonOptions?.rightSemanticLabel}
            onClick={scrollTo}
            isDisplayedOnMediumDown={navigationButtonOptions?.isDisplayedOnMediumDown}
          />
        )}
      </div>
    )
  }
)

export default Carousel
