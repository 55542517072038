import { NEXT_ROUTES } from '@Routes/routes'

import { widgetNextBuildManifest, widgetPublicRuntimeConfig } from '../config'

export const prefetchSearch = (): void => {
  if (typeof widgetNextBuildManifest.pages === 'undefined') {
    return
  }

  const searchPageChunks = widgetNextBuildManifest.pages[NEXT_ROUTES.searchOD] as readonly string[] | undefined

  if (searchPageChunks?.length) {
    searchPageChunks.forEach((chunkUrl) => {
      const linkTag = document.createElement('link')
      linkTag.rel = 'preload'
      linkTag.href = `${widgetPublicRuntimeConfig.frontEndBasePath}/_next/${chunkUrl}`
      linkTag.as = 'script'

      document.head.appendChild(linkTag)
    })
  }
}
