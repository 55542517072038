import { FC } from 'react'
import { Typography } from '@mui/material'

import * as styles from '@DS/components/navigation/search/autocompleteResult/recentSearchResult/styles'

type SimpleRecentSearchProps = {
  placeType?: string
  label: string
}

const SimpleResult: FC<SimpleRecentSearchProps> = ({ placeType, label }) => (
  <>
    <Typography variant="caption" color="textSecondary" aria-hidden>
      {placeType}
    </Typography>
    <div css={styles.recentSearchResultContainer}>
      <Typography component="div" variant="body1" css={styles.recentSearchLabel} aria-hidden>
        {label}
      </Typography>
    </div>
  </>
)

export default SimpleResult
