import { forwardRef, ForwardRefRenderFunction, memo } from 'react'

import { AutocompleteItemProps } from '@DS/components/navigation/search/autocomplete/Autocomplete.types'
import AutocompleteInputProposalResult from '@DS/components/navigation/search/autocompleteResult/inputProposalResult/AutcompleteInputProposalResult'
import AutocompleteRecentSearchResult from '@DS/components/navigation/search/autocompleteResult/recentSearchResult/AutocompleteRecentSearchResult'
import { AutocompleteResultModel, getIsPlace, isRecentSearch } from '@Utils/autocomplete'

export type AutocompleteResultProps = AutocompleteItemProps<AutocompleteResultModel>

const AutocompleteResult: ForwardRefRenderFunction<HTMLDivElement, AutocompleteResultProps> = (
  { model, isHighlighted },
  ref
) => {
  if (isRecentSearch(model)) {
    return <AutocompleteRecentSearchResult model={model} isHighlighted={isHighlighted} ref={ref} />
  }

  if (!getIsPlace(model)) {
    return null
  }

  return <AutocompleteInputProposalResult model={model} isHighlighted={isHighlighted} ref={ref} />
}

export default memo(forwardRef(AutocompleteResult))
