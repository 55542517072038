import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { SPACING_0 } from '@DS/styles/constants/spacing'

export const illustration = (theme: Theme, color: string, isSmall?: boolean): SerializedStyles =>
  css({
    color,
    ...size(rem(80)),
    [theme.breakpoints.up('md')]: {
      ...size(isSmall ? rem(72) : rem(154)),
      margin: `${SPACING_0} ${rem(2)} ${SPACING_0} 0 `,
    },
  })
