import { useCallback, useRef, useState } from 'react'
import { Cart, DeliveryModeForGroupId } from 'invictus-sdk-typescript'

import {
  CartServices,
  DeleteCartItem,
  GetCart,
  HandleNewCartAndScheduleNextCall,
  UpdateCart,
} from '@Hooks/cart/cart.types'
import { useBookSdk } from '@Hooks/sdk/useBookSdk'
import { getDeliveryModesByGroupId } from '@Utils/cart'
import { setToHappen } from '@Utils/time'

// Unique scheduled getCart
let clearTimeoutGetCart: VoidFunction | undefined

type UseCartWithoutStoreParams = {
  onError?: (error: unknown) => void
  onNewCart?: (cart: Cart, deletedItemId?: string) => void
}

const useCartWithoutStore: (params?: UseCartWithoutStoreParams) => CartServices = ({ onError, onNewCart } = {}) => {
  const bookSdk = useBookSdk()
  const [isCartLoading, setIsCartLoading] = useState(false)
  const [isGetCartError, setIsGetCartError] = useState(false)
  const previousNextItemExpirationDateTimeRef = useRef<string | undefined>()
  // Init getCart variable to use it in useCallback deps
  let getCart: null | ((snoozeError?: boolean) => Promise<Cart | null>) = null

  const scheduleNextGetCartCall = useCallback(
    (dateTime: string, hasToMuteGetCartError?: boolean) => {
      clearTimeoutGetCart?.()

      clearTimeoutGetCart = setToHappen(() => {
        getCart?.(hasToMuteGetCartError)
      }, new Date(dateTime))
    },
    [getCart]
  )

  const handleNewCartAndScheduleNextCall = useCallback<HandleNewCartAndScheduleNextCall>(
    (cart, deletedItemId, hasToMuteGetCartError = false) => {
      onNewCart?.(cart, deletedItemId)

      if (
        cart.nextItemExpirationDateTime &&
        cart.nextItemExpirationDateTime !== previousNextItemExpirationDateTimeRef.current
      ) {
        scheduleNextGetCartCall(cart.nextItemExpirationDateTime, hasToMuteGetCartError)
      }
      previousNextItemExpirationDateTimeRef.current = cart.nextItemExpirationDateTime

      return cart
    },
    [onNewCart, scheduleNextGetCartCall]
  )

  getCart = useCallback<GetCart>(
    async (hasToMuteError = false, withServices = true): Promise<Cart | null> => {
      try {
        setIsCartLoading(true)
        setIsGetCartError(false)
        const cart = await bookSdk.getCart(withServices)

        return handleNewCartAndScheduleNextCall(cart, undefined, hasToMuteError)
      } catch (error) {
        setIsGetCartError(true)

        if (!hasToMuteError) {
          onError?.(error)
        }

        return null
      } finally {
        setIsCartLoading(false)
      }
    },
    [bookSdk, handleNewCartAndScheduleNextCall, onError]
  )

  const updateCart = useCallback<UpdateCart>(
    async (
      cart: Cart,
      hasAnInsuranceSelected: boolean,
      isPrereservationSelected: boolean,
      selectedDeliveryModes: DeliveryModeForGroupId[]
    ): Promise<Cart | null> => {
      try {
        setIsCartLoading(true)

        const newCart: Cart = await bookSdk.updateCart(
          cart,
          hasAnInsuranceSelected,
          isPrereservationSelected,
          getDeliveryModesByGroupId(selectedDeliveryModes)
        )

        return handleNewCartAndScheduleNextCall(newCart)
      } catch (error) {
        onError?.(error)

        return null
      } finally {
        setIsCartLoading(false)
      }
    },
    [bookSdk, handleNewCartAndScheduleNextCall, onError]
  )

  const deleteCartItem = useCallback<DeleteCartItem>(
    async (itemId: string): Promise<Cart | null> => {
      try {
        setIsCartLoading(true)
        const cart = await bookSdk.delete(itemId)

        return handleNewCartAndScheduleNextCall(cart, itemId)
      } catch (error) {
        onError?.(error)

        return null
      } finally {
        setIsCartLoading(false)
      }
    },
    [bookSdk, handleNewCartAndScheduleNextCall, onError]
  )

  return {
    handleNewCartAndScheduleNextCall,
    getCart,
    updateCart,
    deleteCartItem,
    isCartLoading,
    isGetCartError,
  }
}

export default useCartWithoutStore
