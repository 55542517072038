import { BookSdk } from 'invictus-sdk-typescript'

import { useSdkState } from '@Contexts/sdk/SdkContext'

export const useBookSdk = (): BookSdk => {
  const { bookSdk } = useSdkState()

  if (bookSdk === undefined) {
    throw new Error('useBookSdk must be used within a SdkProvider with a bookSdk')
  }

  return bookSdk
}
