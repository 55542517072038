import { FC } from 'react'
import { Typography, TypographyProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import * as styles from './styles'

type TagTutoProps = TypographyProps & {
  text: string
}

const TagTuto: FC<TagTutoProps> = ({ text, ...rest }) => {
  const theme = useTheme()

  return (
    <Typography {...rest} variant="subtitle1" css={styles.tagTuto(theme)}>
      {text}
    </Typography>
  )
}

export default TagTuto
