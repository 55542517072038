/* tslint:disable */
/* eslint-disable */
/**
 * Invictus
 * Invictus Backend API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddCompanionFromContactRequest } from '../model';
// @ts-ignore
import { AddCompanionFromContactSuccess } from '../model';
// @ts-ignore
import { Cart } from '../model';
// @ts-ignore
import { CartResetTravelAndRenewSearchRequest } from '../model';
// @ts-ignore
import { CartResetTravelAndRenewSearchSuccess } from '../model';
// @ts-ignore
import { CartUpdateRequest } from '../model';
// @ts-ignore
import { CatalogAirwebBookingRequest } from '../model';
// @ts-ignore
import { CatalogDiscountCardBookingRequest } from '../model';
// @ts-ignore
import { CatalogTerBookingRequest } from '../model';
// @ts-ignore
import { CatalogTerRenewalBookingRequest } from '../model';
// @ts-ignore
import { ConnectedTravelerDiscountCardBookingRequest } from '../model';
// @ts-ignore
import { DiscountCardRenewalBookingRequest } from '../model';
// @ts-ignore
import { OutputErrorMessage } from '../model';
// @ts-ignore
import { TripBookingRequest } from '../model';
// @ts-ignore
import { TripOptionBookingRequest } from '../model';
/**
 * BookingControllerApi - axios parameter creator
 * @export
 */
export const BookingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add companion from a contact information
         * @param {string} xBffKey bff key header
         * @param {AddCompanionFromContactRequest} addCompanionFromContactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompanionFromContact: async (xBffKey: string, addCompanionFromContactRequest: AddCompanionFromContactRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('addCompanionFromContact', 'xBffKey', xBffKey)
            // verify required parameter 'addCompanionFromContactRequest' is not null or undefined
            assertParamExists('addCompanionFromContact', 'addCompanionFromContactRequest', addCompanionFromContactRequest)
            const localVarPath = `/api/v1/book/addCompanionFromContact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCompanionFromContactRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add an item in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {TripBookingRequest} tripBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToCart: async (xBffKey: string, tripBookingRequest: TripBookingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('addToCart', 'xBffKey', xBffKey)
            // verify required parameter 'tripBookingRequest' is not null or undefined
            assertParamExists('addToCart', 'tripBookingRequest', tripBookingRequest)
            const localVarPath = `/api/v1/book`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tripBookingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a airweb offer in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {CatalogAirwebBookingRequest} catalogAirwebBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToCartAirwebOffer: async (xBffKey: string, catalogAirwebBookingRequest: CatalogAirwebBookingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('addToCartAirwebOffer', 'xBffKey', xBffKey)
            // verify required parameter 'catalogAirwebBookingRequest' is not null or undefined
            assertParamExists('addToCartAirwebOffer', 'catalogAirwebBookingRequest', catalogAirwebBookingRequest)
            const localVarPath = `/api/v1/book/airweb`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(catalogAirwebBookingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a option trip in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {TripOptionBookingRequest} tripOptionBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToCartOption: async (xBffKey: string, tripOptionBookingRequest: TripOptionBookingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('addToCartOption', 'xBffKey', xBffKey)
            // verify required parameter 'tripOptionBookingRequest' is not null or undefined
            assertParamExists('addToCartOption', 'tripOptionBookingRequest', tripOptionBookingRequest)
            const localVarPath = `/api/v1/book/trip-option`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tripOptionBookingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a ter offer in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {CatalogTerBookingRequest} catalogTerBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToCartTerOffer: async (xBffKey: string, catalogTerBookingRequest: CatalogTerBookingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('addToCartTerOffer', 'xBffKey', xBffKey)
            // verify required parameter 'catalogTerBookingRequest' is not null or undefined
            assertParamExists('addToCartTerOffer', 'catalogTerBookingRequest', catalogTerBookingRequest)
            const localVarPath = `/api/v1/book/ter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(catalogTerBookingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a discount card in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {CatalogDiscountCardBookingRequest} catalogDiscountCardBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookDiscountCard: async (xBffKey: string, catalogDiscountCardBookingRequest: CatalogDiscountCardBookingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('bookDiscountCard', 'xBffKey', xBffKey)
            // verify required parameter 'catalogDiscountCardBookingRequest' is not null or undefined
            assertParamExists('bookDiscountCard', 'catalogDiscountCardBookingRequest', catalogDiscountCardBookingRequest)
            const localVarPath = `/api/v1/book/discount-card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(catalogDiscountCardBookingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add express discount card for a connected user in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {ConnectedTravelerDiscountCardBookingRequest} connectedTravelerDiscountCardBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookDiscountCardForConnectedTraveler: async (xBffKey: string, connectedTravelerDiscountCardBookingRequest: ConnectedTravelerDiscountCardBookingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('bookDiscountCardForConnectedTraveler', 'xBffKey', xBffKey)
            // verify required parameter 'connectedTravelerDiscountCardBookingRequest' is not null or undefined
            assertParamExists('bookDiscountCardForConnectedTraveler', 'connectedTravelerDiscountCardBookingRequest', connectedTravelerDiscountCardBookingRequest)
            const localVarPath = `/api/v1/book/discount-card-connected-traveler`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectedTravelerDiscountCardBookingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add express discount card renewal for a connected user in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {DiscountCardRenewalBookingRequest} discountCardRenewalBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookDiscountCardRenewal: async (xBffKey: string, discountCardRenewalBookingRequest: DiscountCardRenewalBookingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('bookDiscountCardRenewal', 'xBffKey', xBffKey)
            // verify required parameter 'discountCardRenewalBookingRequest' is not null or undefined
            assertParamExists('bookDiscountCardRenewal', 'discountCardRenewalBookingRequest', discountCardRenewalBookingRequest)
            const localVarPath = `/api/v1/book/discount-card/renewal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discountCardRenewalBookingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add express ter renewal for a connected user in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {CatalogTerRenewalBookingRequest} catalogTerRenewalBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookTerRenewal: async (xBffKey: string, catalogTerRenewalBookingRequest: CatalogTerRenewalBookingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('bookTerRenewal', 'xBffKey', xBffKey)
            // verify required parameter 'catalogTerRenewalBookingRequest' is not null or undefined
            assertParamExists('bookTerRenewal', 'catalogTerRenewalBookingRequest', catalogTerRenewalBookingRequest)
            const localVarPath = `/api/v1/book/ter/renewal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(catalogTerRenewalBookingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an item from the cart and return the cart
         * @param {string} travelId 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTravel: async (travelId: string, xBffKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'travelId' is not null or undefined
            assertParamExists('deleteTravel', 'travelId', travelId)
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('deleteTravel', 'xBffKey', xBffKey)
            const localVarPath = `/api/v1/carts/travels/{travelId}`
                .replace(`{${"travelId"}}`, encodeURIComponent(String(travelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the cart
         * @param {string} xBffKey bff key header
         * @param {boolean} [withServices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCart: async (xBffKey: string, withServices?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('getCart', 'xBffKey', xBffKey)
            const localVarPath = `/api/v1/carts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (withServices !== undefined) {
                localVarQueryParameter['withServices'] = withServices;
            }

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reset travel and renew search
         * @param {string} xBffKey bff key header
         * @param {CartResetTravelAndRenewSearchRequest} cartResetTravelAndRenewSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetTravelFromCartAndRenewSearch: async (xBffKey: string, cartResetTravelAndRenewSearchRequest: CartResetTravelAndRenewSearchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('resetTravelFromCartAndRenewSearch', 'xBffKey', xBffKey)
            // verify required parameter 'cartResetTravelAndRenewSearchRequest' is not null or undefined
            assertParamExists('resetTravelFromCartAndRenewSearch', 'cartResetTravelAndRenewSearchRequest', cartResetTravelAndRenewSearchRequest)
            const localVarPath = `/api/v1/book/resetTravelAndRenewSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cartResetTravelAndRenewSearchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {CartUpdateRequest} cartUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCart: async (xBffKey: string, cartUpdateRequest: CartUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('updateCart', 'xBffKey', xBffKey)
            // verify required parameter 'cartUpdateRequest' is not null or undefined
            assertParamExists('updateCart', 'cartUpdateRequest', cartUpdateRequest)
            const localVarPath = `/api/v1/book/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cartUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingControllerApi - functional programming interface
 * @export
 */
export const BookingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary add companion from a contact information
         * @param {string} xBffKey bff key header
         * @param {AddCompanionFromContactRequest} addCompanionFromContactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCompanionFromContact(xBffKey: string, addCompanionFromContactRequest: AddCompanionFromContactRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddCompanionFromContactSuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCompanionFromContact(xBffKey, addCompanionFromContactRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add an item in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {TripBookingRequest} tripBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addToCart(xBffKey: string, tripBookingRequest: TripBookingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addToCart(xBffKey, tripBookingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a airweb offer in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {CatalogAirwebBookingRequest} catalogAirwebBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addToCartAirwebOffer(xBffKey: string, catalogAirwebBookingRequest: CatalogAirwebBookingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addToCartAirwebOffer(xBffKey, catalogAirwebBookingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a option trip in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {TripOptionBookingRequest} tripOptionBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addToCartOption(xBffKey: string, tripOptionBookingRequest: TripOptionBookingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addToCartOption(xBffKey, tripOptionBookingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a ter offer in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {CatalogTerBookingRequest} catalogTerBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addToCartTerOffer(xBffKey: string, catalogTerBookingRequest: CatalogTerBookingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addToCartTerOffer(xBffKey, catalogTerBookingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a discount card in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {CatalogDiscountCardBookingRequest} catalogDiscountCardBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookDiscountCard(xBffKey: string, catalogDiscountCardBookingRequest: CatalogDiscountCardBookingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookDiscountCard(xBffKey, catalogDiscountCardBookingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add express discount card for a connected user in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {ConnectedTravelerDiscountCardBookingRequest} connectedTravelerDiscountCardBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookDiscountCardForConnectedTraveler(xBffKey: string, connectedTravelerDiscountCardBookingRequest: ConnectedTravelerDiscountCardBookingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookDiscountCardForConnectedTraveler(xBffKey, connectedTravelerDiscountCardBookingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add express discount card renewal for a connected user in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {DiscountCardRenewalBookingRequest} discountCardRenewalBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookDiscountCardRenewal(xBffKey: string, discountCardRenewalBookingRequest: DiscountCardRenewalBookingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookDiscountCardRenewal(xBffKey, discountCardRenewalBookingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add express ter renewal for a connected user in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {CatalogTerRenewalBookingRequest} catalogTerRenewalBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookTerRenewal(xBffKey: string, catalogTerRenewalBookingRequest: CatalogTerRenewalBookingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookTerRenewal(xBffKey, catalogTerRenewalBookingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an item from the cart and return the cart
         * @param {string} travelId 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTravel(travelId: string, xBffKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTravel(travelId, xBffKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the cart
         * @param {string} xBffKey bff key header
         * @param {boolean} [withServices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCart(xBffKey: string, withServices?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCart(xBffKey, withServices, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary reset travel and renew search
         * @param {string} xBffKey bff key header
         * @param {CartResetTravelAndRenewSearchRequest} cartResetTravelAndRenewSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetTravelFromCartAndRenewSearch(xBffKey: string, cartResetTravelAndRenewSearchRequest: CartResetTravelAndRenewSearchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartResetTravelAndRenewSearchSuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetTravelFromCartAndRenewSearch(xBffKey, cartResetTravelAndRenewSearchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {CartUpdateRequest} cartUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCart(xBffKey: string, cartUpdateRequest: CartUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCart(xBffKey, cartUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingControllerApi - factory interface
 * @export
 */
export const BookingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary add companion from a contact information
         * @param {string} xBffKey bff key header
         * @param {AddCompanionFromContactRequest} addCompanionFromContactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompanionFromContact(xBffKey: string, addCompanionFromContactRequest: AddCompanionFromContactRequest, options?: any): AxiosPromise<AddCompanionFromContactSuccess> {
            return localVarFp.addCompanionFromContact(xBffKey, addCompanionFromContactRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add an item in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {TripBookingRequest} tripBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToCart(xBffKey: string, tripBookingRequest: TripBookingRequest, options?: any): AxiosPromise<Cart> {
            return localVarFp.addToCart(xBffKey, tripBookingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a airweb offer in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {CatalogAirwebBookingRequest} catalogAirwebBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToCartAirwebOffer(xBffKey: string, catalogAirwebBookingRequest: CatalogAirwebBookingRequest, options?: any): AxiosPromise<Cart> {
            return localVarFp.addToCartAirwebOffer(xBffKey, catalogAirwebBookingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a option trip in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {TripOptionBookingRequest} tripOptionBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToCartOption(xBffKey: string, tripOptionBookingRequest: TripOptionBookingRequest, options?: any): AxiosPromise<Cart> {
            return localVarFp.addToCartOption(xBffKey, tripOptionBookingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a ter offer in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {CatalogTerBookingRequest} catalogTerBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToCartTerOffer(xBffKey: string, catalogTerBookingRequest: CatalogTerBookingRequest, options?: any): AxiosPromise<Cart> {
            return localVarFp.addToCartTerOffer(xBffKey, catalogTerBookingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a discount card in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {CatalogDiscountCardBookingRequest} catalogDiscountCardBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookDiscountCard(xBffKey: string, catalogDiscountCardBookingRequest: CatalogDiscountCardBookingRequest, options?: any): AxiosPromise<Cart> {
            return localVarFp.bookDiscountCard(xBffKey, catalogDiscountCardBookingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add express discount card for a connected user in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {ConnectedTravelerDiscountCardBookingRequest} connectedTravelerDiscountCardBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookDiscountCardForConnectedTraveler(xBffKey: string, connectedTravelerDiscountCardBookingRequest: ConnectedTravelerDiscountCardBookingRequest, options?: any): AxiosPromise<Cart> {
            return localVarFp.bookDiscountCardForConnectedTraveler(xBffKey, connectedTravelerDiscountCardBookingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add express discount card renewal for a connected user in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {DiscountCardRenewalBookingRequest} discountCardRenewalBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookDiscountCardRenewal(xBffKey: string, discountCardRenewalBookingRequest: DiscountCardRenewalBookingRequest, options?: any): AxiosPromise<Cart> {
            return localVarFp.bookDiscountCardRenewal(xBffKey, discountCardRenewalBookingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add express ter renewal for a connected user in the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {CatalogTerRenewalBookingRequest} catalogTerRenewalBookingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookTerRenewal(xBffKey: string, catalogTerRenewalBookingRequest: CatalogTerRenewalBookingRequest, options?: any): AxiosPromise<Cart> {
            return localVarFp.bookTerRenewal(xBffKey, catalogTerRenewalBookingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an item from the cart and return the cart
         * @param {string} travelId 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTravel(travelId: string, xBffKey: string, options?: any): AxiosPromise<Cart> {
            return localVarFp.deleteTravel(travelId, xBffKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the cart
         * @param {string} xBffKey bff key header
         * @param {boolean} [withServices] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCart(xBffKey: string, withServices?: boolean, options?: any): AxiosPromise<Cart> {
            return localVarFp.getCart(xBffKey, withServices, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary reset travel and renew search
         * @param {string} xBffKey bff key header
         * @param {CartResetTravelAndRenewSearchRequest} cartResetTravelAndRenewSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetTravelFromCartAndRenewSearch(xBffKey: string, cartResetTravelAndRenewSearchRequest: CartResetTravelAndRenewSearchRequest, options?: any): AxiosPromise<CartResetTravelAndRenewSearchSuccess> {
            return localVarFp.resetTravelFromCartAndRenewSearch(xBffKey, cartResetTravelAndRenewSearchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the cart and return the cart
         * @param {string} xBffKey bff key header
         * @param {CartUpdateRequest} cartUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCart(xBffKey: string, cartUpdateRequest: CartUpdateRequest, options?: any): AxiosPromise<Cart> {
            return localVarFp.updateCart(xBffKey, cartUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingControllerApi - object-oriented interface
 * @export
 * @class BookingControllerApi
 * @extends {BaseAPI}
 */
export class BookingControllerApi extends BaseAPI {
    /**
     * 
     * @summary add companion from a contact information
     * @param {string} xBffKey bff key header
     * @param {AddCompanionFromContactRequest} addCompanionFromContactRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public addCompanionFromContact(xBffKey: string, addCompanionFromContactRequest: AddCompanionFromContactRequest, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).addCompanionFromContact(xBffKey, addCompanionFromContactRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add an item in the cart and return the cart
     * @param {string} xBffKey bff key header
     * @param {TripBookingRequest} tripBookingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public addToCart(xBffKey: string, tripBookingRequest: TripBookingRequest, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).addToCart(xBffKey, tripBookingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a airweb offer in the cart and return the cart
     * @param {string} xBffKey bff key header
     * @param {CatalogAirwebBookingRequest} catalogAirwebBookingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public addToCartAirwebOffer(xBffKey: string, catalogAirwebBookingRequest: CatalogAirwebBookingRequest, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).addToCartAirwebOffer(xBffKey, catalogAirwebBookingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a option trip in the cart and return the cart
     * @param {string} xBffKey bff key header
     * @param {TripOptionBookingRequest} tripOptionBookingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public addToCartOption(xBffKey: string, tripOptionBookingRequest: TripOptionBookingRequest, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).addToCartOption(xBffKey, tripOptionBookingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a ter offer in the cart and return the cart
     * @param {string} xBffKey bff key header
     * @param {CatalogTerBookingRequest} catalogTerBookingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public addToCartTerOffer(xBffKey: string, catalogTerBookingRequest: CatalogTerBookingRequest, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).addToCartTerOffer(xBffKey, catalogTerBookingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a discount card in the cart and return the cart
     * @param {string} xBffKey bff key header
     * @param {CatalogDiscountCardBookingRequest} catalogDiscountCardBookingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public bookDiscountCard(xBffKey: string, catalogDiscountCardBookingRequest: CatalogDiscountCardBookingRequest, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).bookDiscountCard(xBffKey, catalogDiscountCardBookingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add express discount card for a connected user in the cart and return the cart
     * @param {string} xBffKey bff key header
     * @param {ConnectedTravelerDiscountCardBookingRequest} connectedTravelerDiscountCardBookingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public bookDiscountCardForConnectedTraveler(xBffKey: string, connectedTravelerDiscountCardBookingRequest: ConnectedTravelerDiscountCardBookingRequest, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).bookDiscountCardForConnectedTraveler(xBffKey, connectedTravelerDiscountCardBookingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add express discount card renewal for a connected user in the cart and return the cart
     * @param {string} xBffKey bff key header
     * @param {DiscountCardRenewalBookingRequest} discountCardRenewalBookingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public bookDiscountCardRenewal(xBffKey: string, discountCardRenewalBookingRequest: DiscountCardRenewalBookingRequest, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).bookDiscountCardRenewal(xBffKey, discountCardRenewalBookingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add express ter renewal for a connected user in the cart and return the cart
     * @param {string} xBffKey bff key header
     * @param {CatalogTerRenewalBookingRequest} catalogTerRenewalBookingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public bookTerRenewal(xBffKey: string, catalogTerRenewalBookingRequest: CatalogTerRenewalBookingRequest, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).bookTerRenewal(xBffKey, catalogTerRenewalBookingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an item from the cart and return the cart
     * @param {string} travelId 
     * @param {string} xBffKey bff key header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public deleteTravel(travelId: string, xBffKey: string, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).deleteTravel(travelId, xBffKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the cart
     * @param {string} xBffKey bff key header
     * @param {boolean} [withServices] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public getCart(xBffKey: string, withServices?: boolean, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).getCart(xBffKey, withServices, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary reset travel and renew search
     * @param {string} xBffKey bff key header
     * @param {CartResetTravelAndRenewSearchRequest} cartResetTravelAndRenewSearchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public resetTravelFromCartAndRenewSearch(xBffKey: string, cartResetTravelAndRenewSearchRequest: CartResetTravelAndRenewSearchRequest, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).resetTravelFromCartAndRenewSearch(xBffKey, cartResetTravelAndRenewSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the cart and return the cart
     * @param {string} xBffKey bff key header
     * @param {CartUpdateRequest} cartUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingControllerApi
     */
    public updateCart(xBffKey: string, cartUpdateRequest: CartUpdateRequest, options?: AxiosRequestConfig) {
        return BookingControllerApiFp(this.configuration).updateCart(xBffKey, cartUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
