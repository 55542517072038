import { type AbstractSdkConstructorParameters, type ControllerApiConstructor } from './abstract'
import { AbstractSelfInitSdk } from './abstractSelfInit'
import { Catch } from './catchError'
import {
  type ApplePayInputPaymentMean,
  type Cart,
  type CartBuyer,
  type CartItem,
  type CartTraveler,
  type Countries,
  type CreateFinalizationAndUpdateContactsRequest,
  type DeliveryModeForGroupId,
  type FinalizationBuyer,
  FinalizationControllerApi,
  type FinalizationDonation,
  type FinalizationInsurance,
  type FinalizationOutput,
  type FinalizationTraveler,
  type FinalizationTravelerDiscountCard,
  type FinalizationTravelers,
  type FinalizedOrder,
  type FinalizeExistingFinalizationRequest,
  type GetStationAssistanceServiceUrlRequestV2,
  type PaymentCardBrands,
  type Paypage,
  type PrereserveRequest,
  type RegisteredPaymentCard,
  type SelectedDonationResponse,
  type SplitPaymentSchedule,
  type StationAssistanceServiceResponse,
  type UnregisteredPaymentCard,
} from './generated'
import { type TrackingSdk } from './tracking'

const finalizationDiscountCardFromTravelerCart = (
  traveler: CartTraveler
): FinalizationTravelerDiscountCard | undefined =>
  traveler.discountCard?.number ? { number: traveler.discountCard.number } : undefined

const finalizationTravelersFromCartItem = (cartItem: CartItem): FinalizationTraveler[] => {
  if (cartItem.trip) {
    return cartItem.trip.travelers.map((traveler) => ({
      civility: traveler.civility,
      dateOfBirth: traveler.dateOfBirth,
      discountCard: finalizationDiscountCardFromTravelerCart(traveler),
      firstName: traveler.firstName,
      lastName: traveler.lastName,
      phoneNumber: traveler.phoneNumber,
      email: traveler.email,
      id: traveler.id,
    }))
  }

  if (cartItem.subscription) {
    return cartItem.subscription.travelers.map((traveler) => ({
      civility: traveler.civility,
      dateOfBirth: traveler.dateOfBirth,
      discountCard: finalizationDiscountCardFromTravelerCart(traveler),
      firstName: traveler.firstName,
      lastName: traveler.lastName,
      phoneNumber: traveler.phoneNumber,
      email: traveler.email,
      id: traveler.id,
    }))
  }

  return []
}

const finalizationTravelersFromTripCart = (cartItems: CartItem[]): FinalizationTravelers[] =>
  cartItems.map((cartItem) => ({
    tripId: cartItem.id,
    travelers: finalizationTravelersFromCartItem(cartItem),
  }))

const finalizationBuyerFromCart = (cartBuyer: CartBuyer): FinalizationBuyer => ({
  civility: cartBuyer.civility ?? 'MADAM',
  email: cartBuyer.email ?? '',
  firstName: cartBuyer.firstName ?? '',
  lastName: cartBuyer.lastName ?? '',
  phoneNumber: cartBuyer.phoneNumber,
  unsubscribeToNewsletter: cartBuyer.unsubscribeToNewsletter,
  address: cartBuyer.address,
})

const NO_INSURANCES: [] = []

const containAnInsurancesSelected = (cartItem: CartItem) =>
  cartItem.trip?.cartInsurance != null && cartItem.trip?.cartInsurance.selected

const createFinalizationInsuranceForTripIdFrom = (cartItem: CartItem) => ({
  insuranceId: cartItem.trip?.cartInsurance?.id ?? '',
  tripId: cartItem.id ?? '',
})

const mapInsurancesToFinalizationInsuranceForTripId = (cartItems: CartItem[]) =>
  cartItems
    .filter((cartItem) => containAnInsurancesSelected(cartItem))
    .map((cartItem) => createFinalizationInsuranceForTripIdFrom(cartItem))

export const finalizationInsuranceFromTripCart = (
  cartItems: CartItem[],
  hasAnInsuranceSelected: boolean
): FinalizationInsurance[] =>
  hasAnInsuranceSelected ? mapInsurancesToFinalizationInsuranceForTripId(cartItems) : NO_INSURANCES

export const finalizationDonationsFromCart = (donations: SelectedDonationResponse[]): FinalizationDonation[] =>
  donations.map(({ donationId, amount }) => ({ donationId, amount }))

export class FinalizeSdk extends AbstractSelfInitSdk<FinalizationControllerApi> {
  readonly #trackingSdk: TrackingSdk

  constructor(trackingSdk: TrackingSdk, ...[configuration, ...rest]: AbstractSdkConstructorParameters) {
    super(configuration, ...rest)
    this.#trackingSdk = trackingSdk
  }

  protected getControllerApiConstructor(): ControllerApiConstructor<FinalizationControllerApi> {
    return FinalizationControllerApi
  }

  @Catch<FinalizationOutput>()
  async createFinalizationAndUpdateContacts(
    deliveryModes: DeliveryModeForGroupId[],
    cart: Cart,
    hasAnInsuranceSelected = false,
    hasGenericNewsletter = false
  ): Promise<FinalizationOutput> {
    const request: CreateFinalizationAndUpdateContactsRequest = {
      deliveryModes,
      travelers: finalizationTravelersFromTripCart(cart.items),
      buyer: finalizationBuyerFromCart(cart.buyer),
      insurances: finalizationInsuranceFromTripCart(cart.items, hasAnInsuranceSelected),
      donations: finalizationDonationsFromCart(cart.selectedDonations),
      genericNewsletter: hasGenericNewsletter ? cart.genericNewsletterMarketSuggestion?.value : undefined,
    }
    const response = await this.api.create(this.userSdk.getBffHeader(), request, this.userSdk.createAxiosOptions())

    return response.data
  }

  @Catch<FinalizationOutput>()
  async getFinalization(): Promise<FinalizationOutput> {
    const response = await this.api.getFinalization(this.userSdk.getBffHeader(), this.userSdk.createAxiosOptions())

    return response.data
  }

  @Catch<Countries>()
  async getCountries(deliveryModes: string[]): Promise<Countries> {
    const response = await this.api.getCountries(
      this.userSdk.getBffHeader(),
      deliveryModes,
      this.userSdk.createAxiosOptions()
    )

    return response.data
  }

  @Catch<PaymentCardBrands>()
  async getPaymentCardBrands(prefix: string): Promise<PaymentCardBrands> {
    const response = await this.api.getPaymentCardBrands(
      prefix,
      this.userSdk.getBffHeader(),
      this.userSdk.createAxiosOptions()
    )

    return response.data
  }

  @Catch<FinalizationOutput>()
  async finalizeExistingFinalizationWithUnregisteredPaymentCard(
    unregisteredCard: UnregisteredPaymentCard,
    deliveryModes: DeliveryModeForGroupId[],
    returnUrl: string,
    cart: Cart
  ): Promise<FinalizationOutput> {
    const request: FinalizeExistingFinalizationRequest = {
      unregisteredCard,
      deliveryModes,
      returnUrl,
      travelers: finalizationTravelersFromTripCart(cart.items),
      buyer: finalizationBuyerFromCart(cart.buyer),
    }
    const response = (await this.api.pay(this.userSdk.getBffHeader(), request, this.userSdk.createAxiosOptions())).data
    this.#trackingSdk.updateDatalayer(response?.finalizedOrder?.metadata?.datalayer)

    return response
  }

  @Catch<FinalizationOutput>()
  async finalizeExistingFinalizationWithRegisteredPaymentCard(
    registeredCard: RegisteredPaymentCard,
    deliveryModes: DeliveryModeForGroupId[],
    returnUrl: string,
    cart: Cart
  ): Promise<FinalizationOutput> {
    const request: FinalizeExistingFinalizationRequest = {
      registeredCard,
      deliveryModes,
      returnUrl,
      travelers: finalizationTravelersFromTripCart(cart.items),
      buyer: finalizationBuyerFromCart(cart.buyer),
    }
    const response = (await this.api.pay(this.userSdk.getBffHeader(), request, this.userSdk.createAxiosOptions())).data
    this.#trackingSdk.updateDatalayer(response?.finalizedOrder?.metadata?.datalayer)

    return response
  }

  @Catch<FinalizationOutput>()
  async finalizeExistingFinalizationWithPaypagePaymentMean(
    paypage: Paypage,
    deliveryModes: DeliveryModeForGroupId[],
    returnUrl: string,
    cart: Cart
  ): Promise<FinalizationOutput> {
    const request: FinalizeExistingFinalizationRequest = {
      paypage,
      deliveryModes,
      returnUrl,
      travelers: finalizationTravelersFromTripCart(cart.items),
      buyer: finalizationBuyerFromCart(cart.buyer),
    }
    const response = (await this.api.pay(this.userSdk.getBffHeader(), request, this.userSdk.createAxiosOptions())).data
    this.#trackingSdk.updateDatalayer(response?.finalizedOrder?.metadata?.datalayer)

    return response
  }

  @Catch<FinalizationOutput>()
  async finalizeExistingFinalizationWithApplePayPaymentMean(
    applePayInputPaymentMean: ApplePayInputPaymentMean,
    deliveryModes: DeliveryModeForGroupId[],
    cart: Cart
  ): Promise<FinalizationOutput> {
    const request: FinalizeExistingFinalizationRequest = {
      applePayInputPaymentMean,
      deliveryModes,
      travelers: finalizationTravelersFromTripCart(cart.items),
      buyer: finalizationBuyerFromCart(cart.buyer),
    }
    const response = (await this.api.pay(this.userSdk.getBffHeader(), request, this.userSdk.createAxiosOptions())).data
    this.#trackingSdk.updateDatalayer(response?.finalizedOrder?.metadata?.datalayer)

    return response
  }

  @Catch<FinalizedOrder>()
  async finalizeResume(finalizationId: string): Promise<FinalizedOrder> {
    const response = (
      await this.api.resumePayment(finalizationId, this.userSdk.getBffHeader(), this.userSdk.createAxiosOptions())
    ).data
    this.#trackingSdk.updateDatalayer(response?.metadata?.datalayer)

    return response
  }

  @Catch<FinalizedOrder | undefined>()
  async prereserve(
    deliveryModes: DeliveryModeForGroupId[],
    cart: Cart,
    hasGenericNewsletter = false
  ): Promise<FinalizedOrder | undefined> {
    const request: PrereserveRequest = {
      travelers: finalizationTravelersFromTripCart(cart.items),
      buyer: finalizationBuyerFromCart(cart.buyer),
      insurances: [],
      donations: [],
      genericNewsletter: hasGenericNewsletter ? cart.genericNewsletterMarketSuggestion?.value : undefined,
    }
    const response = await this.api.prereserve(this.userSdk.getBffHeader(), request, this.userSdk.createAxiosOptions())

    return response.data.finalizedOrder
  }

  @Catch<string | undefined>()
  async initApplePaySdk(): Promise<string | undefined> {
    const response = await this.api.initApplePaySdk(this.userSdk.getBffHeader(), this.userSdk.createAxiosOptions())

    return response.data.applePay
  }

  @Catch<SplitPaymentSchedule[] | undefined>()
  async getSplitPaymentSchedule(): Promise<SplitPaymentSchedule[] | undefined> {
    const response = await this.api.getSplitPaymentSchedule(
      this.userSdk.getBffHeader(),
      this.userSdk.createAxiosOptions()
    )

    return response.data.splitPaymentSchedule
  }

  @Catch<StationAssistanceServiceResponse>()
  async getStationAssistanceServiceUrl(
    request: GetStationAssistanceServiceUrlRequestV2
  ): Promise<StationAssistanceServiceResponse> {
    const response = await this.api.getStationAssistanceServiceUrlV2(
      this.userSdk.getBffHeader(),
      request,
      this.userSdk.createAxiosOptions()
    )

    return response.data
  }
}
