import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem } from 'polished'

import { SPACING_1, SPACING_2, SPACING_3 } from '@DS/styles/constants/spacing'
import { scrollbarTheming } from '@Styles/scrollbar'

const DEFAULT_MAX_HEIGHT = 600

export const autocomplete = (theme: Theme, maxHeight?: number): SerializedStyles =>
  css(scrollbarTheming('transparent', theme.palette.primary.main), {
    borderBottom: `${rem(8)} solid ${theme.palette.common.white}`,
    borderLeft: 0,
    borderRight: 0,
    borderTop: `${rem(8)} solid ${theme.palette.common.white}`,
    outline: 'none',
    padding: `${SPACING_2} 0`,
    width: '100%',
    ...(maxHeight && {
      maxHeight: rem(maxHeight),
    }),
    [theme.breakpoints.up('md')]: {
      maxHeight: rem(maxHeight ?? DEFAULT_MAX_HEIGHT),
      overflow: 'auto',
      padding: `${SPACING_2} 0 ${SPACING_3}`,
    },
  })

export const sectionTitleFirstChild = css({
  marginTop: 0,
})

export const sectionTitle = (): SerializedStyles =>
  css({
    margin: `${SPACING_1} ${SPACING_3}`,
  })
