import { v4 as uuid } from 'uuid'

import { type WithRequired } from './utils/type'
import { type AbstractSdkConstructorParameters, type ControllerApiConstructor } from './abstract'
import { AbstractSelfInitSdk } from './abstractSelfInit'
import { Catch } from './catchError'
import { Behaviour, DecisionActionType, type DecisionSourceType } from './enums'
import { type SdkError } from './error'
import {
  type AgeRank,
  type DiscountCardGroup,
  type GetItineraryOutput,
  ItineraryControllerApi,
  type ItineraryOutput,
  type ItineraryPassenger,
  type ItineraryPet,
  type ItineraryRequest,
  type ItineraryRequestBranchEnum,
  type LongItineraryProposals,
  type MetadataY,
  type MoreItinerariesRequest,
  type Place,
  type SeatMap,
  type ShortItineraries,
  type ShortItineraryFilters,
} from './generated'
import { ITINERARY_ID_STORAGE, StorageSdk } from './storage'
import { type TrackingSdk } from './tracking'

export type TransportType = 'TRAIN' | 'BUS_CARPOOLING'

type ItineraryRequestUserNavigationContext = 'IS_BUSINESS' | 'IS_NOT_BUSINESS'

export type SearchOptions = {
  forceDisplayResults?: boolean
  forcedBranch?: ItineraryRequestBranchEnum
  selectedOutwardOfferId?: string
}

export type SearchItineraryActionType = 'NAVIGATION' | 'WEEKLY'

export type ItineraryRequestData = {
  destination: Place | undefined
  fceCode?: string
  filteredTransporters?: string[] | null
  inwardDate: string | null
  isBusiness?: boolean
  isDirectJourney?: boolean
  isOutwardDateArrivalAt?: boolean
  metadataY?: MetadataY
  minChangeTimeMinutes: number | undefined
  pets?: ItineraryPet[]
  searchItineraryAction?: SearchItineraryActionType
  shortItineraryFilters?: ShortItineraryFilters
  strictMode?: boolean
  trainExpected?: boolean
  travelers?: ItineraryPassenger[]
  origin: Place | undefined
  outwardDate: string | null
  via?: Place | null
  wishBike?: boolean
}

const sessionStorage = new StorageSdk().sessionStorage()

export class ItinerarySdk extends AbstractSelfInitSdk<ItineraryControllerApi> {
  readonly #trackingSdk: TrackingSdk

  protected getControllerApiConstructor(): ControllerApiConstructor<ItineraryControllerApi> {
    return ItineraryControllerApi
  }

  constructor(trackingSdk: TrackingSdk, ...args: AbstractSdkConstructorParameters) {
    super(...args)
    this.#trackingSdk = trackingSdk
    let persistedItineraryId = ''

    try {
      // TODO : virer ce patch temporaire (IVTS-30340)
      persistedItineraryId = sessionStorage?.getItem(ITINERARY_ID_STORAGE) || ''

      if (persistedItineraryId !== '' && /^"/.test(persistedItineraryId)) {
        persistedItineraryId = JSON.parse(persistedItineraryId)
      }
    } catch (err) {} // eslint-disable-line no-empty

    this.#itineraryId = persistedItineraryId || uuid()
  }

  // only one search at same time! :)
  #itineraryId = ''

  #outwardDate?: string

  #isOutwardDateArrivalAt?: boolean

  #isDirectJourney?: boolean

  #via?: Place | null

  #minChangeTimeMinutes: number | undefined

  #filteredTransporters?: string[] | null

  #inwardDate?: string

  #origin?: Place

  #destination?: Place

  #travelers: ItineraryPassenger[] = []

  #pets: ItineraryPet[] = []

  #fceCode?: string

  #wishBike?: boolean

  #strictMode?: boolean

  #trainExpected = true

  #metadataY?: MetadataY

  #shortItineraryFilters?: ShortItineraryFilters

  #searchItineraryAction?: SearchItineraryActionType

  #isBusiness?: boolean

  get itineraryId(): string {
    return this.#itineraryId
  }

  get outwardDate(): string | undefined {
    return this.#outwardDate
  }

  get isOutwardDateArrivalAt(): boolean | undefined {
    return this.#isOutwardDateArrivalAt
  }

  get isDirectJourney(): boolean | undefined {
    return this.#isDirectJourney
  }

  get minChangeTimeMinutes(): number | undefined {
    return this.#minChangeTimeMinutes
  }

  get inwardDate(): string | undefined {
    return this.#inwardDate
  }

  get origin(): Place | undefined {
    return this.#origin
  }

  get destination(): Place | undefined {
    return this.#destination
  }

  get via(): Place | null | undefined {
    return this.#via
  }

  get travelers(): ItineraryPassenger[] {
    return this.#travelers
  }

  get pets(): ItineraryPet[] {
    return this.#pets
  }

  get fceCode(): string | undefined {
    return this.#fceCode
  }

  get wishBike(): boolean | undefined {
    return this.#wishBike
  }

  get strictMode(): boolean | undefined {
    return this.#strictMode
  }

  get trainExpected(): boolean {
    return this.#trainExpected
  }

  get metadataY(): MetadataY | undefined {
    return this.#metadataY
  }

  setExistingItineraryId(itineraryId: string): ItinerarySdk {
    this.#itineraryId = itineraryId

    return this
  }

  fill = ({
    destination,
    fceCode,
    filteredTransporters,
    inwardDate,
    isBusiness,
    isDirectJourney,
    isOutwardDateArrivalAt,
    metadataY,
    minChangeTimeMinutes,
    pets,
    searchItineraryAction,
    shortItineraryFilters,
    strictMode,
    trainExpected,
    travelers,
    origin,
    outwardDate,
    via,
    wishBike,
  }: ItineraryRequestData): ItinerarySdk => {
    this.#outwardDate = outwardDate ?? this.#outwardDate
    this.#isOutwardDateArrivalAt = isOutwardDateArrivalAt ?? this.#isOutwardDateArrivalAt
    this.#isDirectJourney = isDirectJourney ?? this.#isDirectJourney
    this.#inwardDate = inwardDate ?? this.#inwardDate
    this.#origin = origin ?? this.#origin
    this.#destination = destination ?? this.#destination
    this.#via = via || via === null ? via : this.#via
    this.#minChangeTimeMinutes = minChangeTimeMinutes
    this.#filteredTransporters = filteredTransporters !== undefined ? filteredTransporters : this.#filteredTransporters
    this.#travelers = travelers ?? this.#travelers
    this.#pets = pets ?? this.#pets
    this.#fceCode = fceCode !== this.#fceCode ? fceCode : this.#fceCode
    this.#wishBike = wishBike ?? this.#wishBike
    this.#trainExpected = trainExpected ?? this.#trainExpected
    this.#strictMode = strictMode ?? this.#strictMode
    this.#metadataY = metadataY
    this.#shortItineraryFilters = shortItineraryFilters ?? this.#shortItineraryFilters
    this.#isBusiness = isBusiness ?? this.#isBusiness
    this.#searchItineraryAction = searchItineraryAction

    return this
  }

  outwardOnly = (): ItinerarySdk => {
    this.#inwardDate = undefined

    return this
  }

  resetItinerary = (): ItinerarySdk => {
    this.#itineraryId = uuid()

    return this
  }

  setTransportType(transportType: TransportType): ItinerarySdk {
    this.#trainExpected = transportType === 'TRAIN'

    return this
  }

  @Catch<GetItineraryOutput>()
  async refreshItinerary(itineraryId: string, outward: boolean, plan = false): Promise<GetItineraryOutput> {
    this.setExistingItineraryId(itineraryId)
    const response = await this.api.getItinerary(
      itineraryId,
      this.userSdk.getBffHeader(),
      outward,
      plan,
      this.userSdk.createAxiosOptions()
    )
    this.#trackingSdk.updateDatalayer(response?.data?.output?.metadata?.datalayer)

    return response.data
  }

  async searchOutwardLongDistance(): Promise<ItineraryOutput | undefined> {
    return this.search({ forcedBranch: 'SHOP' })
  }

  async searchInwardLongDistance(selectedOutwardOfferId: string): Promise<ItineraryOutput | undefined> {
    // Le BFF stockera l'identifiant de l'aller, qui sera plus tard utilisé avec l'id du retour
    // pour effectuer la réservation
    return this.search({
      forcedBranch: 'SHOP',
      selectedOutwardOfferId,
    })
  }

  async searchShortDistance(): Promise<ShortItineraries | undefined> {
    const data = await this.search({ forcedBranch: 'PLAN' })

    return data?.shortDistance?.results
  }

  @Catch<ItineraryOutput | undefined>()
  async search(
    { forceDisplayResults, forcedBranch, selectedOutwardOfferId }: SearchOptions = { forceDisplayResults: false }
  ): Promise<ItineraryOutput | undefined> {
    return Promise.resolve(this.getItinerary(selectedOutwardOfferId, forcedBranch, forceDisplayResults))
  }

  /**
   *
   * @param selectedOutwardId selected offer if any
   * @param branch shop, plan or null
   * @param forceDisplayResults force display results
   */
  private async getItinerary(
    selectedOutwardId?: string,
    branch?: ItineraryRequestBranchEnum,
    forceDisplayResults = true
  ): Promise<ItineraryOutput> {
    const o = this.#origin
    const d = this.#destination
    const v = this.#via

    if (o && d) {
      const userNavigationContext: ItineraryRequestUserNavigationContext[] = []

      if (this.#isBusiness === true) {
        userNavigationContext.push('IS_BUSINESS')
      } else if (this.#isBusiness === false) {
        userNavigationContext.push('IS_NOT_BUSINESS')
      }
      const request: ItineraryRequest = {
        schedule: {
          outward: {
            date: this.#outwardDate ?? new Date().toISOString(),
            arrivalAt: this.#isOutwardDateArrivalAt,
          },
          inward: this.#inwardDate
            ? {
                date: this.#inwardDate,
              }
            : undefined,
        },
        mainJourney: { origin: o, destination: d, via: v ?? undefined },
        passengers: this.#travelers ?? [],
        pets: this.#pets ?? [],
        itineraryId: this.#itineraryId,
        selectedOutwardId,
        branch,
        forceDisplayResults,
        trainExpected: this.#trainExpected,
        fceCode: this.#fceCode,
        wishBike: this.#wishBike ?? false,
        strictMode: this.#strictMode ?? false,
        directJourney: this.#isDirectJourney,
        minChangeTimeMinutes: this.#minChangeTimeMinutes ?? undefined,
        transporterLabels: this.#filteredTransporters ?? undefined,
        metadataY: this.#metadataY,
        shortItineraryFilters: this.#shortItineraryFilters,
        searchItineraryAction: this.#searchItineraryAction,
        userNavigation: userNavigationContext,
      }

      const response = await this.api.itineraries(
        this.userSdk.getBffHeader(),
        request,
        this.userSdk.createAxiosOptions()
      )
      this.#trackingSdk.updateDatalayer(response?.data?.metadata?.datalayer)

      return response.data
    }

    return Promise.reject({
      isSdkError: true,
      type: 'functionalError',
      message: { behaviour: Behaviour.UNRECOVERABLE },
    } as SdkError)
  }

  @Catch<LongItineraryProposals | undefined>()
  async more(next: boolean, outward: boolean, decisionSource?: DecisionSourceType): Promise<ItineraryOutput> {
    const request: MoreItinerariesRequest = {
      itineraryId: this.#itineraryId,
      next,
      outward,
      trainExpected: this.#trainExpected,
      metadataY: {
        decisionAction: next ? DecisionActionType.NEXT : DecisionActionType.PREVIOUS,
      },
    }

    if (decisionSource) {
      request.decisionSource = decisionSource
    }

    const response = await this.api.moreItineraries(
      this.userSdk.getBffHeader(),
      request,
      this.userSdk.createAxiosOptions()
    )
    this.#trackingSdk.updateDatalayer(response?.data?.metadata?.datalayer)

    return response.data
  }

  @Catch<SeatMap>()
  async getSeatMap(offerId: string, segmentId: string): Promise<SeatMap> {
    const response = await this.api.getSeatMap(
      this.#itineraryId,
      offerId,
      segmentId,
      this.userSdk.getBffHeader(),
      this.userSdk.createAxiosOptions()
    )

    return response.data
  }
}

export type ItineraryOutputWithLongDistance = WithRequired<ItineraryOutput, 'longDistance'>
export const isLongDistanceResult = (response?: ItineraryOutput): response is ItineraryOutputWithLongDistance =>
  !!response?.longDistance

export type ItineraryOutputWithLongDistanceInformation = ItineraryOutputWithLongDistance &
  Required<ItineraryOutputWithLongDistance['longDistance']['information']>
export const isDisplayPassengersResult = (
  response?: ItineraryOutput
): response is ItineraryOutputWithLongDistanceInformation => !!response?.longDistance?.information

export type ItineraryOutputWithShortDistance = WithRequired<ItineraryOutput, 'shortDistance'>
export const isShortDistanceResult = (response?: ItineraryOutput): response is ItineraryOutputWithShortDistance =>
  !!response?.shortDistance

export const filterDiscountCards = (
  discountCardGroups: DiscountCardGroup[],
  ageRank: AgeRank | undefined,
  age: number | undefined
): DiscountCardGroup[] => {
  const filteredDiscountCardGroup: DiscountCardGroup[] = []
  discountCardGroups.forEach((cardGroup) => {
    const filteredCards = cardGroup.cardOptions.filter(
      (card) =>
        ((ageRank && ageRank.maxAge != null ? ageRank.maxAge <= card.maxAge : true) ||
          (ageRank &&
            ageRank.ageRequired === true &&
            age &&
            (card.maxClaimAge != null ? age <= card.maxClaimAge : age <= card.maxAge))) &&
        ((ageRank && (ageRank.minAge != null ? ageRank.minAge >= card.minAge : true)) ||
          (ageRank && ageRank.ageRequired === true && age && age >= card.minAge))
    )

    if (filteredCards?.length > 0) {
      filteredDiscountCardGroup.push({
        label: cardGroup.label,
        cardOptions: filteredCards,
        accountStorageTooltip: cardGroup.accountStorageTooltip,
      })
    }
  })

  return filteredDiscountCardGroup
}
